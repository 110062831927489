import React, { Component } from 'react';

import KedroLocal from '../images/KedroSnowflake/kedro_options.png';


class SnowflakeKedro extends Component {
    render() {
        return(
            <div className="project-box">
                <h1 className="project-title-text">
                    Solving Common MLOps problems with Kedro and Snowflake 
                </h1>
                <p className="project-main-text" style={{display:'block'}}>
                    As I work on ML projects I always try to keep the three V's of MLOps in mind: Velocity, Validation, and Versioning.
                    As investigated in Operationalizing Machine Learning: An Interview Study (&nbsp; 
                         <a href="https://arxiv.org/pdf/2209.09125.pdf" target="_blank" style={{textDecoration:"none", }}>
                            snowflake-udf-performance-testing
                         </a> ), these three V's are essential for a successful ML project. Since this project was still in the POC stage,
                          my main focus is primarily on keeping a high velocity and good versioning, and less on validation. 
                   
                    <br/>
                    <br/>
                    The main challenges I was facing were:
                        <ol> 
                            <li> 
                            I was getting to the point where the pipeline was hard to conceptualize without any visualizations. This reduced my velocity since I would need to take time to review the project structure every time I started a new development session. 
                            </li>
                        <br />
                        
                            <li>
                            It was hard to onboard others to the project due to the increasing size of the project. This also reduced the velocity of the project.
                            </li>
                        <br />
                            <li>
                            I needed to do a better job of tracking dataset and model versions since I was starting to develop multiple datasets and models. 
                            </li>
                        <br />
                            <li>
                            I was starting to spend a considerable amount of time waiting for processing tasks to run. A slow feedback loop was leading to a slower velocity.
                            </li>
                        <br />
                            <li>
                            I needed to start thinking about ways to easily deploy my pipeline to a production environment. This wasn't isn't part of the three V's, but was important for the next stage of the project.
                            </li>
                        <br />
                </ol>

                </p>

                <p className="project-main-text" style={{display:'block'}}>

                These are fairly common ML challenges, so a number of solutions exist to solve them. I knew I wanted a tool that would allow me to visualize my ML pipelines (preferably open source), so some options I looked into included Zen ML, Airflow, AWS Sagemaker, and Kedro. 
                After some initial testing, I decided to go with Kedro, and I have really loved using it. 

                <br />
                <br />

                <i> “Kedro is an open-source Python framework to create reproducible, maintainable, and modular data science code. It uses software engineering best practices to help you build production-ready data science pipelines.”  (From the docs) </i>
                
                <br />
                <br />
                
                The Kedro project framework was similar to the project structure we were currently using so migrating wasn’t too bad. To migrate (generally), instead of wrapping a pipeline in a class, we moved the class initialization code out to the Kedro data catalog and parameter store, then registered the functions as Kedro nodes.

                </p>
                

                <p className="project-main-text" style={{display:'block'}}>
                Here is how I solved some of my challenges with Snowflake and Kedro:

                   <br /> 
                   <br />
                   <ol> 
                            <li> <i>
                            I was getting to the point where the pipeline was hard to conceptualize without any visualizations. This reduced my velocity since I would need to take time to review the project structure every time I started a new development session. 
                            </i> </li> 
                            <br />

                        Kedro helps in two ways here. First, having this extensible framework out of the box made it easier to manage some of the complexity of the project by making it easy to split out the data loading into Kedro's data catalog. 
                            <br />
                            <br />
                        Second Kedro has a pipeline visualization tool, so you can easily view how all of your pipelines are connected. As part of the kedro pipeline, nodes are created and node dependencies are automatically determined based on connections between the inputs and outputs of the nodes. These connections can then be visualized using the Kedro Viz extension. 
                        
                        <br />
                        <br />
                        
                            <li> <i>
                            It was hard to onboard others to the project due to the increasing size of the project. This also reduced the velocity of the project.
                            </i> </li>
                        <br />
                        Since we weren't using a standardized framework and didn't have any pipeline visualization tools, it took a long time to onboard new project contributors. With the Kedro framework, new contributors can use Kedro viz to see the full pipeline, and they can refer to the Kedro docs to get familiar with the basic project structure. 
                        <br />
                        <br />


                            <li> <i>
                            I needed to do a better job of tracking dataset and model versions since I was starting to develop multiple datasets and models. 
                            </i> </li>
                        <br />
                        The inputs to our ML project are dynamic, so both features and the datasets themselves might change from run to run. This makes it hard to keep track of the results for different runs. Kedro made it easy to integrate ML Flow to handle our model versioning and results tracking. With ML Flow I could easily reference all of the datasets and inputs used during experimentation. 
                        <br />
                        <br />

                            <li><i>
                            I was starting to spend a considerable amount of time waiting for processing tasks to run. A slow feedback loop was leading to a slower velocity.
                            </i> </li>
                        <br />
                        I offloaded some of the computing I was doing locally and pushed it into Snowflake. I was already pushing intermediate datasets to Snowflake, so it was logical to do some of the simpler computations using Snowpark. 
                        <br />
                        <br />

                            <li> <i>
                            I needed to start thinking about ways to easily deploy my pipeline to a production environment. This wasn't isn't part of the three V's, but was important for the next stage of the project.
                            </i> </li>
                        <br />
                        Kedro comes in helpful here as well. Once your pipeline is developed It supports many deployment options like Airflow or Sagemaker. For the POC stages of this project all we needed to do was dockerize the Kedro project and then run it on an EC2 instance, but using the framework means we can easily scale up in the future. 
                        <br />
                        <br />
                </ol>

                </p>


                <p className="project-main-text" style={{display:'block'}}>
                    My new local development workflow looks similar to this:
                </p>


                <div className="blog-pics">
                    <img src={KedroLocal} alt = "local dev image" width='100%'/>
                </div>


                <p className="project-main-text" style={{display:'block'}}>
                Kedro comes with some SQL data support out of the box, but for a more full integration with Snowflake I had to create some new Kedro DataSet classes. The kedro framework is extensible, so this was a relatively straightforward and well-documented process. You can find some of the code for creating the DataSets on my Github 
                page (&nbsp; 
                         <a href="https://github.com/PrestonBlackburn/kedro-snowflake-extensions" target="_blank" style={{textDecoration:"none", }}>
                            snowflake-udf-performance-testing
                         </a> ).
                </p>




                <p className="project-main-text" style={{display:'block'}}>
                    Outcomes:

                   <ol>

                   <li>
                   By switching our project to use the Kedro framework we were able to increase our velocity, improve our versioning, and prepare our project for deployment. Kedro Snowflake DataSets make it easy to integrate Snowflake into your ML pipeline. 
                    </li> 
                    <li>
                    By adopting a standardized framework I can more easily start up and switch between future projects.

                    </li>
                    </ol>

                    In my next post, I'll talk about deploying Kedro pipelines natively to Snowflake instead of another pipeline tool like Airflow.
                </p>



            </div>          
        )
    }
}

export default SnowflakeKedro;