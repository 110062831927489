import React, { useState, useEffect } from 'react';
import { fetchOilProduction } from '../../dashAPI';
import { Line, Bar, Pie, Doughnut } from 'react-chartjs-2';

import styles from './Chart.module.css';


const DoughnutChart = ({ data: { US, Year, Europe, World} } ) => {
  
     {/* Check to make sure oil production data is available make sure length does not equal zero */}
    if(!US) {
        return 'Loading...'
    }

    const worldMinus = (World - US - Europe) / 1000

    const data = {
        maintainAspectRatio: false,
        responsive: false,
        labels: ["US", "Europe", "World"],
        datasets: [
          {
            data: [US/1000, Europe/1000, worldMinus],
            backgroundColor: [
                'rgba( 63, 81, 181, 0.9)',
                'rgba(244, 65, 38, 0.9)',
                'rgba(33, 148, 243, 0.9)'

            ],
            hoverBackgroundColor: [
                'rgba( 63, 81, 181, 0.5)',
                'rgba(244, 65, 38, 0.5)',
                'rgba(33, 148, 243, 0.5)'

            ]
          }]
    }

    const options = {
        legend: {
          display: true,
          position: "top"
        },
        elements: {
          arc: {
            borderWidth: 2
          }
        }
    };


    const DoughnutChart = (
    <Doughnut 
         data={data}  options={options}
    /> );




    return (
        <div className={styles.container}>
            {DoughnutChart}
            <div className={styles.pieLabel}>
                Millions of Barrels Consumed Daily
            </div>
        </div>

    )
}

export default DoughnutChart