import React from 'react';
import { Card, CardContent, Typography, Grid, StylesProvider } from '@material-ui/core'
import styles from './Cards.module.css';
import CountUp from 'react-countup';
import cx from 'classnames';


const WidgetCards = ({ data: { US, Year, Europe, World} } ) => {
    
    {/*  Need this because data isn't loaded in right away, but it only takes a fraction of a second    */}
    if(!US) {
        return 'Loading...'
    }

    return (
        <div className={styles.container}>

<Grid container spacing={3} justify="space-around"> 
                <Grid item component={Card} xs={12} md={3} className={cx(styles.card, styles.USemissions)}> 
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                            US Oil Consumption
                        </Typography>
                        <Typography variant="h5">
                            <CountUp end={US * 1000} separator=','/>
                        </Typography>
                        <Typography>
                            barrels per day
                        </Typography>
                        <Typography color="textSecondary">
                            {Year}
                        </Typography>
                    </CardContent>
                </Grid>
                <Grid item component={Card} xs={12} md={3} className={cx(styles.card, styles.EuropeEmissions)}> 
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                            Europe Oil Consumption
                        </Typography>
                        <Typography variant="h5">
                            <CountUp end ={Europe * 1000} separator=','/>
                        </Typography>
                        <Typography>
                            barrels per day
                        </Typography>
                        <Typography color="textSecondary">
                            {Year}
                        </Typography>
                    </CardContent>
                </Grid>
                <Grid item component={Card} xs={12} md={3} className={cx(styles.card, styles.WorldEmissions)}> 
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                            World Oil Consumption
                        </Typography>
                        <Typography variant="h5">
                            <CountUp end={World * 1000} separator=','/>
                        </Typography>
                        <Typography >
                            barrels per day 
                        </Typography>
                        <Typography color="textSecondary">
                            {Year}
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>

        </div>
    )
}

export default WidgetCards