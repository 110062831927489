import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import '../styles.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Projects from './Projects';

const useStyles = makeStyles((theme) => ({
  projectButton: {
    background: '#f44336',
    borderRadius: '24px',
    padding: '10px',
    paddingLeft: '15px',
    paddingRight: '15px',
    minWidth: '32px',
    textDecoration: 'none',

    color: 'white',
    fontWeight: 'bold',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#ff7961',

  },
  },
}));


function MobileOverlay() {
  const classes = useStyles();

  return (

    <div className='mobilemaintext'>
      <h1 className="mobiletitletext">
        Preston Blackburn
      </h1>
      <p className="mobiledetailtext">
      Product Ownership <br />
      Data Science <br />
      Cloud Development <br />
      </p>
      <a href="/projects" style={{textDecoration: 'none'}}>
          <Button className={classes.projectButton}>
            Projects
          </Button>
      </a>
        
    </div>
  );
}

export default MobileOverlay

