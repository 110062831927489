import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import TFSageMaker from '../../images/BeerRecPost/tf_sagemaker_beer.png';


const useStyles = makeStyles((theme) => ({

    heroContent: {
      backgroundColor: '#F6F6F6',
      padding: theme.spacing(8, 0, 6),
      paddingTop: '150px',
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    heroButtonStyle: {
      background: '#f44336',
      color: 'white',
      '&:hover': {
        backgroundColor: '#ff7961',
  
    },
    },
    heroButton2Style: {
      background: 'white',
      color: '#f44336',

    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
      
    },
    cardContent: {
      flexGrow: 1,
    },
    dateContent: {
      fontSize: '14px',
      padding: '2px',
      color: 'grey',
      paddingLeft: '20px',
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));



export default function WebsiteUpdateCard() {
    const classes = useStyles();
    return (
        <Grid item key={2} xs={12} sm={6} md={4}>
            
        <Card className={classes.card}>
        <a className="linkclass" href="/projects/RecommendationProject"  color="black">
          <CardActionArea>
          <CardMedia
            className={classes.cardMedia}
            image={TFSageMaker}
            title="Web dev serverless update project"
          />
          </CardActionArea>
        </a>
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2">
                Recommendation Systems With TensorFlow Recommenders and AWS
            </Typography>
              <Typography>
                 Creating a recommendation system for beers using TensorFlow Recommenders and hosting with AWS SageMaker endpoints. 
              </Typography>
            </CardContent>
            <Typography className={classes.dateContent}>
              10/24/21
            </Typography>                

          <CardActions>
          <a href="/projects/RecommendationProject" style={{textDecoration: 'none',}}>
            <Button size="small" className={classes.heroButton2Style}>
              View
            </Button>
            </a>
          </CardActions>
        </Card>
      </Grid>
    )
}
