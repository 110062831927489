import React, { Component } from 'react';

import GatsbyExample from '../images/MLInProd/Gatsby_Styled.jpg';
import StyleArch from '../images/MLInProd/Arbitrary_stylet_ransfer_structure.jpg';




class StyleProject extends Component {
    render() {
        return(
            <div className="project-box">
                <h1 className="project-title-text">
                    Machine Learning In Production - Style Transfer
                </h1>
                <p className="project-main-text">
                Over the last couple of months, I have been diving into generative machine learning models,
                 and I wanted to create something that people could interact with. In this project,
                 I incorporated many of the techniques and frameworks I’ve learned into one project. Furthermore,
                  I hadn’t seen a project like this done online before, so I thought it would be a good challenge to get the app up and running.
                  <br />
                  <br />
                  Some of the tools I used in this project:
                  <br>
                  </br>
                  <br>
                  </br>
                  - Django (Python)
                  <br>
                  </br>
                  - TensorFlow/TensorFlow lite
                  <br>
                  </br>
                  - React (JavaScript)
                  <br>
                  </br>
                  - Nginx
                  <br>
                  </br>
                  - Docker
                  <br>
                  </br>
                  - Docker Compose
                  <br>
                  </br>
                  - AWS
                </p>
                <p className="project-main-text" style={{display:'block'}}>
                     View the web app here: &nbsp;
                    <a href="http://artist.prestonblackburn.com/" target="_blank"> Instant Artist </a>
                </p>


                <h2 className="project-title-text-2">
                    What Is Style Transfer?
                </h2>
                <p className="project-main-text">
                    Style transfer is a machine learning algorithm that attempts to take the “style” from one image and impose it on another. 
                    <br />
                     <br />
                     In the example below Van Gough’s Starry Night is the style image and the picture of my dog, Gatsby, is the target image.
                      In the generated image you can see that the textures and swirl-like patterns have been transferred to the target image.
                </p>
                <div className="blog-pics">
                    <img src={GatsbyExample} alt = "Gatsby Example" width='90%'/>
                </div>

                <h2 className="project-title-text-2">
                    Background
                </h2>
                <p className="project-main-text">
                First off, I could have used Tensorflow.js instead of hosting the machine learning model in the backend to improve the app's performance and decrease complexity. 
                However, the point of this app was to complete a full-stack React/Django/Nginx/Docker ML project with the ML model hosted by the backend. 
                I had used all the tools before, but it was a challenge putting them all together. 
                    <br />
                     <br />
                     The app is hosted on AWS on the smallest ec2 (server) instance. That means I have minimal resources to work with.
                      Limited resources can pose a challenge for machine learning models, especially for those that deal with images.
                       There is no database for this app because I didn’t want to store people’s photos. 
                       <br />
                     <br />
                     While I have designed some simple style transfer models previously, I used a pre-made model from Magenta for this app.
                      The Magenta model is specifically optimized for fast style transfer, which is perfect to use on the tiny ec2 instance.
                </p>
                <p className="project-main-text" style={{display:'block'}}>
                     The TensorFlow model can be found at: &nbsp;
                    <a href= "https://tfhub.dev/google/magenta/arbitrary-image-stylization-v1-256/2" target="_blank"> TensorFlow Model</a>
                </p>

                <h2 className="project-title-text-2">
                    Building + Deploying the App
                </h2>

                <p className="project-main-text">
                    My general process in creating the app was to design the app (Figma), develop the backend (python/Django), 
                    build frontend (Javascript/React), and then prep the app for production (Docker + NGINX). The main challenges 
                    I ran into came after the last step once the app was deployed on AWS.
                    <br/>
                    <br/>
                    The app is deployed on Amazon EC2 using a t2.micro instance. That means the app is limited to 1 virtual CPU and 1 GB of RAM. 
                    It quickly became apparent that the CPU and RAM limits would be an issue when I deployed my model and it instantly crashed when
                     it received its first request. Using docker, I was able to see that the CPU would go straight to 99% usage and crash the app 
                     when trying to run the model. To fix the issue I could either pay for a larger instance or figure out how to make the model run
                      on fewer resources, and I wasn’t about to pay more to get around poor optimization. 
                    <br/>
                    <br/>
                    Luckily TensorFlow has a framework, TensorFlow lite, made specifically to be light weight. The TensorFlow lite model was much smaller 
                    (about 3MB vs 80MB), but the drawback is that the images passed to the model need to be scaled to a specific size. I made the updates
                     to the app and deployed it again. The app was no longer crashing. However, it was not long until I ran into another bottleneck. 
                     <br/>
                    <br/>
                    The loading/calculation times were taking several seconds, but the TensorFlow lite calculations should take under a second. This time
                     the culprit was on the frontend. I had allowed the app to accept images of any size, and large images take time to send to the server. 
                     To make the user’s life easier I decided to downsize the images on the frontend as well. I don’t like websites that make the user figure
                      out how to downsize large images and re-upload them. Once the frontend was updated, the app was fully deployed in its current state.
                       I still have some bugs with using the app on mobile, but the web build has been holding up. 
                    <br/>
                    <br/>
                    After experiencing resource-specific development issues with the app, I’ve learned the value of deploying an app in an environment 
                    limited to the resources available in production. I could have iterated and troubleshot much faster if I had limited the docker resources to match those of my production environment. 
                </p>

                <h2 className="project-title-text-2">
                    About the Model
                </h2>

                <p className="project-main-text" style={{display:'block'}}>
                    Magenta had designed the model based on the “Exploring the structure of a real-time, arbitrary neural artistic stylization network” paper by Ghiasi, et. al.. Check out the paper if you want a deep dive into the model. 
                    <br/>
                    <br/>
                    The paper can be found here: <a href="https://arxiv.org/abs/1705.06830" target="_blank"> Exploring the structure of a real-time, arbitrary neural artistic stylization network </a> 
                    <br/>
                    <br/>
                    Early style transfer models learn low level features, ex: the brushstrokes in a Monet, that can be applied to other images.
                     In the work by Ghiasi et. al., they created a model that can generalize over a much greater range than a single style and even 
                     predict styles that the model hasn’t been trained on.
                     <br/>
                    <br/>
                    In production only two models are used, the style prediction network and the style transfer network. The style prediction network 
                    uses the style image to generate an embedding vector, which is fed to the style transfer network. Then the style transfer network
                     uses the embedding vector and content image to predict the stylized image. During training a third image classification model, VGG, 
                     is used to determine loss between the style image, content image, and stylized image. 
                     <br/>
                    <br/>
                    The model also allows for the user to determine the strength of the stylization. By applying the style prediction network to the content image,
                     a new embedding vector is generated. Interpolation can then be done between the vector from the style image and the content image to determine
                      stylistic strength. While this feature is not currently in my app, it is something I might add in later. 
                </p>

                <div className="blog-pics">
                <br></br>
                    Model Architecture
                    <br></br>
                    <br></br>
                    <img src={StyleArch} alt = "style-transfer-model" width='70%'/>
                    <br></br>
                    Source: Ghiasi et. al. 2017

                </div>

                <h2 className="project-title-text-2">
                    Furture Steps
                </h2>
                <p className="project-main-text">
                There are some features I still want to add to the app, such as:
                <br />
                <br />
                - Adding a Loading spinner for uploads
                  <br>
                  </br>
                  - Drag and drop example style images
                  <br>
                  </br>
                  - Add security certificate for https (I’ve already done this on some websites)
                  <br>
                  </br>
                  - Address some bugs – mobile seems to have some issues
                </p>


                <h2 className="project-title-text-2">
                    References
                </h2>
                <div className="references">
                    <p className="ref-strings">
                    Ghiasi, G., Lee, H., Kudlur, M., Dumoulin, V., Shlens, J. 2017. "Exploring the structure of a real-time, arbitrary neural artistic stylization network." arXiv preprint arXiv:1705.06830.
                    <br/>
                    <br/>
                    Some Other Resources I Used:
                    <br/>
                    <br/>
                    A Blog by Mausam Gaurav: <a href="https://datagraphi.com/blog/post/2020/8/30/docker-guide-build-a-fully-production-ready-machine-learning-app-with-react-django-and-postgresql-on-docker" target="_blank">React, Django, Docker Tutorial </a>
                    <br/>
                    <br/>
                    A Blog by Reiichiro Nakano, who deployed a similar model using TensorFlow.js:  <a href="https://magenta.tensorflow.org/blog/2018/12/20/style-transfer-js/" target="_blank">Porting Arbitrary Style Transfer to the Browser </a>
                    <br/>
                    <br/>
                    I had the idea for the project after watching one of Nicholas Renotte's videos: <a href="https://www.youtube.com/channel/UCHXa4OpASJEwrHrLeIzw7Yg" target="_blank"> His Youtube Channel </a>
                    </p>
                </div>



            </div>          
        )
    }
}

export default StyleProject;