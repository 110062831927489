import React, { useState} from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link, NavLink } from 'react-router-dom';
import StyleTransferCard from './blogcards/StyleTransferCard';
import GPA2103Card from './blogcards/2103Card';
import ProdCard from './blogcards/ProductionCard';
import ShrinkCard from './blogcards/ShrinkCard';
import EOSCard from './blogcards/EOSCard';
import FingerCard from './blogcards/FingerprintCard';
import DashboardCard from './blogcards/Dashboard';
import EcommerceCard from './blogcards/EcommerceCard';
import WebsiteUpdateCard from './blogcards/WebsiteUpdateCard';
import StockCard from './blogcards/StockCard';
import BeerCard from './blogcards/BeerCard';
import BeerCard2 from './blogcards/BeerCard2';
import BeerCard3 from './blogcards/BeerCard3';
import DataEngCensusCard from './blogcards/DataEngCensusCard';
import DockerCard from '././blogcards/DockerDriverCard';
import DockerGPUCard from '././blogcards/DockerGPUCard';
import AquaponicsCard1 from './blogcards/AquaponicsCard1';
import SnowflakeUDFCard from './blogcards/SnowflakeUDFCard';
import UdfPerfCard from './blogcards/UdfPerfCard';
import KedroSFCard from './blogcards/KedroSnowflakeCard';
import IcePick from './blogcards/IcePickCard';
import Modal from './Modal';

const useStyles = makeStyles((theme) => ({

  heroContent: {
    backgroundColor: '#F9F9F9',
    padding: theme.spacing(8, 0, 6),
    paddingTop: '150px',
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  heroButtonStyle: {
    background: '#f44336',
    color: 'white',
    '&:hover': {
      backgroundColor: '#ff7961',

  },
  },
  heroButton2Style: {
    background: 'white',
    color: '#f44336',
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  dateContent: {
    fontSize: '14px',
    padding: '2px',
    color: 'grey',
    paddingLeft: '20px',

  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Preston
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

     


function Projects() {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false)

  return(

    <React.Fragment>
    <main>

      {/* Hero unit */}
      <div className={classes.heroContent}>
        <Container maxWidth="sm">
          <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
            My Projects
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" paragraph>
            In my free time I like to learn about machine learning, Snowflake, and cloud technologies.
            Many of my projects involve the industry I currently work in, data engineering.
          </Typography>
          <div className={classes.heroButtons}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Button variant="contained" className={classes.heroButtonStyle} color="inherit" onClick={() => setIsOpen(true)}>
                  Contact Me
                </Button>
                <Modal open={isOpen} onClose={() => setIsOpen(false)} />

              </Grid>
              <Grid item>
                <a href="/projects/KedroSnowflake"  target="_blank" style={{textDecoration: 'none',}} >
                <Button variant="outlined" className={classes.heroButton2Style}>
                  Featured Project
                </Button>
              </a>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <Container className={classes.cardGrid} maxWidth="md">
        {/* End hero unit */}



        {/* Start Tiles */}
        <Grid container spacing={4} >
          <IcePick/>
          <KedroSFCard />
          <UdfPerfCard />
          <SnowflakeUDFCard/>
          <AquaponicsCard1 />
          <DockerGPUCard />
          <DockerCard />
          <DataEngCensusCard />
          <BeerCard3 />
          <BeerCard2 />
          <BeerCard />
          <StockCard />
          <WebsiteUpdateCard />
          <StyleTransferCard />
          <ShrinkCard />
          <EOSCard />
          <GPA2103Card />
          <ProdCard />
          <DashboardCard />
          <FingerCard />
          <EcommerceCard />
          
        </Grid>
        {/* End Tiles */}
      </Container>


    </main>

    {/* Footer */}
    <footer className={classes.footer}>
      <Typography variant="h6" align="center" gutterBottom>
        More to come!
      </Typography>
      <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
        
      </Typography>
      <Copyright />
    </footer>
    {/* End footer */}

  </React.Fragment>
  )
}

export default Projects;