import React, { useState, useEffect } from 'react';
import { fetchOilProduction } from '../../dashAPI';
import { Line, Bar } from 'react-chartjs-2';

import styles from './Chart.module.css';


const LineChart = () => {
    const [oilProduction, setOilProduction] = useState([]); {/* setting state for functionaal component */}

    {/*      Fetching data and setting the states      */}
    useEffect(() => {
        const fetchAPI = async () => {
            setOilProduction(await fetchOilProduction());
        }

        console.log(oilProduction)

        fetchAPI();
    }, []);

    {/*    creating two different charts     */}
    {/* Check to make sure oil production data is available make sure length does not equal zero */}
    const lineChart = (
        oilProduction.length !== 0  
        ? (     
        <Line 
            data={{

                labels: oilProduction.map(({ Year } ) => Year),
                datasets: [{
                    data: oilProduction.map(({ totalChina }) => totalChina),
                    label: 'China - Oil Production 1000 bbls/day',
                    borderColor: 'rgba(33, 148, 243, 0.9)',
                    fill: true,
                }, {
                    data: oilProduction.map(({ totalUS }) => totalUS),
                    label: 'US - Oil Production 1000 bbls/day',
                    borderColor: 'rgba( 63, 81, 181, 0.9)',
                    fill: true,    
                }, {
                    data: oilProduction.map(({ totalEurope }) => totalEurope),
                    label: 'Europe - Oil Production 1000 bbls/day',
                    borderColor: 'rgba(244, 65, 38, 0.9)',
                    fill: true,    
                }],
            }}
        />) : null
    );



    return (
        <div className={styles.container}>
            {lineChart}
        </div>
    )
}

export default LineChart