import React, { Component } from 'react';


import ReadTarfile from '../images/SFandTFModels/read_tarfile.png';
import ZipModel from '../images/SFandTFModels/zip_tfhub_model.png';
import LocalTest from '../images/SFandTFModels/test_model_local.png';
import SnowSQLConnect from '../images/SFandTFModels/Snowsql_Connect.png';
import SnowSQLCommands from '../images/SFandTFModels/snowsql_commands.png';
import SFPackage from '../images/SFandTFModels/sf_package.png';



class SnowflakeUDF extends Component {
    render() {
        return(
            <div className="project-box">
                <h1 className="project-title-text">
                    Using Tensorflow Hub Models In Snowflake
                </h1>
                <p className="project-main-text" style={{display:'block'}}>
                        In this post, I'll use TensorFlow hub to download a pre-trained model and use it to make predictions in Snowflake. Integration with TensorFlow hub and Snowflake allows you to run state-of-the-art
                         models directly on your data. To run the model directly in Snowflake, we’ll take advantage of Snowflake UDFs (User Defined Functions). Snowflake UDFs allow you to create custom functions to 
                         perform operations that are not available through the functions provided by Snowflake. UDFs can be created in various languages, but in this case, we'll use python. A similar process can also
                          be used to load custom or tuned models created in Tensorflow.
                </p>


                <h2 className="project-title-text-2">
                    Pre-recs
                </h2>
                <p className="project-main-text" style={{display:'block'}}>
                    <li> 
                        Snowflake trial account (free)
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li>  &nbsp;  <a href="https://signup.snowflake.com/" target="_blank" style={{textDecoration:"none", }}> Free Snowflake Trial Link </a> </li>
                        </ol>
                     </li>

                     <li> 
                        Snowsql
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li> &nbsp;  <a href="https://docs.snowflake.com/en/user-guide/snowsql.html" target="_blank" style={{textDecoration:"none", }}> Installing Snowsql Instructions </a> </li>
                        </ol>
                     </li>

                     <li> 
                        Python (preferably with Anaconda)
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li> &nbsp;  <a href="https://www.anaconda.com/products/distribution" target="_blank" style={{textDecoration:"none", }}> Get Anaconda </a>  </li>
                        </ol>
                     </li>

                </p>
                
                <h2 className="project-title-text-2">
                    Step 1: selecting a model

                </h2>
                <p className="project-main-text" style={{display:'block'}}>
                There are a few considerations to keep in mind when selecting a TensorFlow Hub model to use in Snowflake. First off, a Snowflake UDF will not have a public internet connection. This means that we will need to download the TensorFlow model instead of specifying the model URL. 
                    <br/>
                    <br />
                    Since we will be downloading the model and supplying it to Snowflake we need to consider the size of the model. If the model is too big the Snowflake UDF will not be able to load it. The size limit is not listed anywhere, but if UDFs are based on serverless functions like AWS Lambda functions, the size limit will be around 500MB. 
                    <br/>
                    <br />
                    For this example, I'll use the Universal Sentance Encoder Lite encoder: &nbsp;  <a href="https://tfhub.dev/google/universal-sentence-encoder-lite/2" target="_blank" style={{textDecoration:"none", }}> Tensorflow Hub - universal sentance encoder </a> 
                    <br/>
                    <br />

                    Next, we need to make sure that the model only uses packages supported by Snowflake. Snowflake uses an Anaconda environment with a ton of pre-installed packages. To query the available tensorflow packages you can run this query from a snowflake worksheet:
                </p>

                <div className="blog-pics">
                    <img src={SFPackage} alt = "Snowflake packages available" width='70%'/>
                </div>

                <p className="project-main-text" style={{display:'block'}}>
                    Once we have determined that the model is supported we can download the TensorFlow Hub model locally. 
                </p>



                <h2 className="project-title-text-2">
                    Step 2: Prep + Upload the Model To Snowflake
                </h2>

                <p className="project-main-text" style={{display:'block'}}>
                The tensorflow model will be compressed as a .tar.gz, but we want to use .zip compression since that seems to be the preferred method in the Snowflake docs  (
                &nbsp;  <a href="https://docs.snowflake.com/en/developer-guide/udf/python/udf-python-creating.html#label-udf-python-read-write-file" target="_blank" style={{textDecoration:"none", }}> Snowflake import file docs </a> 
                ). There are various ways to uncompress and compress files, but we’ll use python again.
              
                </p>

                <div className="blog-pics">
                    <img src={ReadTarfile} alt = "tarfile code" width='70%'/>
                </div>

                <p className="project-main-text" style={{display:'block'}}>
                    We’ll also make sure that the model is working after it has been unzipped in the script below. This will also come in handy when we create our script for the Snowflake UDF.
                </p>
                
                <div className="blog-pics">
                    <img src={LocalTest} alt = "tarfile code" width='70%'/>
                </div>

                
                <p className="project-main-text" style={{display:'block'}}>
                If the model is working create a zip package:
                </p>


                <div className="blog-pics">
                    <img src={ZipModel} alt = "tarfile code" width='70%'/>
                </div>


                <p className="project-main-text" style={{display:'block'}}>
                Then we can upload the zipped model to a Snowflake stage using SnowSQL. 
                    <br/>
                    <br />
                    We need to create a Snowflake stage before we can upload the model file. In a Snowflake worksheet you can run:
                    <br/>
                    <br />
                    <code>create stage model_repo; </code>
                    <br/>
                    <br />
                    Then we can upload the file using Snowsql from the command line:
                    <br/>
                    <br />
                    To connect to Snowflake from SnowSQL:
                    <br/>
                    <br />
                    <code> snowsql -a wl02607.us-east-2.aws -u Prestontrial -P </code>
                    <br/>
                    <br />
                    Then enter your trial account  password when promted. Select the database and schema where you want to the model to be saved. Then use the PUT command to upload the file (this can take a while for big models)

                </p>

                <div className="blog-pics">
                    <img src={SnowSQLCommands} alt = "tarfile code" width='70%'/>
                </div>

                <p className="project-main-text" style={{display:'block'}}>
                    Now that we have the model in Snowflake, we just need to create a UDF to use the model. 

                </p>
                

                <h2 className="project-title-text-2">
                    Step 3: Create the Snowflake UDF
                </h2>


                <p className="project-main-text" style={{display:'block'}}>
                Before you can create the UDF you need to enable Anaconda python packages for your trial account by following these steps from Snowflake
                &nbsp;  <a href="https://docs.snowflake.com/en/developer-guide/udf/python/udf-python-packages.html#using-third-party-packages-from-anaconda" target="_blank" style={{textDecoration:"none", }}> Using Thrid Party Packages In Snowflake </a> 
              
                </p>


                <p className="project-main-text" style={{display:'block'}}>
                    NOnce you have enabled Anaconda packages,  you can create the python UDF. The main components of the UDF are
                   
                   <li>
                   Specify the imports and zip files to load
                    </li> 
                    <li>
                    Start the UDF and import packages
                    </li>
                    <li>
                    Read the zipped file from stage
                    </li>
                    <li>
                    Save the zipped file locally to the UDF “/temp” folder
                    </li>
                    <li>
                    Read the zipped model file
                    </li>
                    <li>
                    Use the model for inference for the the input to the UDF
                    </li>
                    <li>
                    Output an array of embeddings
                    </li>
                    
                </p>


                <p className="project-main-text" style={{display:'block'}}>
                See the full code on my github page
                &nbsp;  <a href="https://github.com/PrestonBlackburn/Snowflake-With-Tensorflow-Hub/blob/main/sf_with_tf_hub_example.sql" target="_blank" style={{textDecoration:"none", }}> Github Examples </a> 
                </p>

                <p className="project-main-text" style={{display:'block'}}>
                    With a similar process, you can train and load your own TensorFlow models directly into Snowflake. 
                 </p>



            </div>          
        )
    }
}

export default SnowflakeUDF;