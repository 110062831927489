import React, { Component } from 'react';


import LocalExec from '../images/udfPerf/local_execution_example.png';
import SnowflakeSmallExec from '../images/udfPerf/sf_ex_sm_wh.png';
import SnowflakeLargeExec from '../images/udfPerf/sf_ex_lg_wh.png';
import SfvsLocal from '../images/udfPerf/sf_vs_local.png';
import SfPandasSmall from '../images/udfPerf/sf_pandas_small.png';
import SfPandasLarge from '../images/udfPerf/sf_pandas_large.png';
import SfPandasSmallAll from '../images/udfPerf/sf_sm_with_pandas.png';
import SfPandasLargeAll from '../images/udfPerf/sf_lg_with_pandas.png';
import SfBatchSmall from '../images/udfPerf/sf_batch_sm.png';
import SfBatchLarge from '../images/udfPerf/sf_batch_lg.png';




class UDFPerformance extends Component {
    render() {
        return(
            <div className="project-box">
                <h1 className="project-title-text">
                    Snowflake UDF Performance Considerations
                </h1>
                <p className="project-main-text" style={{display:'block'}}>
                    In June 2022 Snowflake released snowpark for python. This new feature can be game-changing by allowing you to
                    easily run data science workloads at scale without spinning up any additional infrastructure. Since python 
                    support in Snowpark is still fairly new, developers are still experimenting and creating workflows around
                    the new Python APIs. My goal for this post is to provide some insight into the performance of python 
                    User-Defined Functions (UDFs), specifically comparing UDFs to Vectorized UDFs. 
                    <br/>
                    <br/>
                    With regular Snowflake UDFs you are able to execute python (and other languages) code directly in Snowflake
                     typically on a row-by-row basis. Vectorized UDFs allow you to execute python code batches instead of row-by-row
                      to achieve better performance and to more easily work with DataFrames in Snowflake UDFs. Pandas DataFrames 
                      are commonly used for data preparation for data science. However, the main performance benefits from using 
                      Pandas DataFrames come from the ability to batch calculations and execute them using optimized cpython code.
                       In a traditional UDF, this optimization is lost because it executes row-by-row, so the operations can't be 
                       batched. 
                </p>


                <h2 className="project-title-text-2">
                    Performance Testing
                </h2>


                <p className="project-main-text" style={{display:'block'}}>
                    To test performance I chose to focus on two simple functions, one to add two numbers together and another to
                     compute the factorial of two numbers and add the results. For both functions, I implement one version using
                      plain python and another with vectorized methods using Pandas. Then I test the functions on datasets of
                       various sizes ranging from 10^2 rows to 10^8 rows. In the chart below you can see that the vectorized 
                       methods are much faster, but they have a higher initial compute cost. The dashed lines represent the best
                        score from 5 runs, and the points represent each of the 5 runs. Keep in mind that these results are
                         specific to my machine, so your results may vary. The code for these experiments can be found on my 
                         GitHub page: &nbsp; 
                         <a href="https://github.com/PrestonBlackburn/snowflake-udf-performance-testing" target="_blank" style={{textDecoration:"none", }}>
                            snowflake-udf-performance-testing
                         </a>

                </p>
                
                <div className="blog-pics">
                    <img src={LocalExec} alt = "local exeuction performance" width='100%'/>
                </div>

                <p className="project-main-text" style={{display:'block'}}>
                Now that we have a baseline, we can translate these functions to Snowpark UDFs and test them in Snowflake. 
                Some slight re-working of the functions is required to get them into a format that can be uploaded to
                 Snowflake, but the Snowpark UDF decorator makes the uploading process simple. An important note for
                  packaging is that for vectorizing the UDFs we need to add the pandas package to the UDFs, since the
                   vectorized data is manipulated using pandas dataframes.

                   <br /> 
                   <br />

                   For testing in Snowflake I also took into account the size of the warehouse to see its impact on
                   performance. Snowflake warehouses have a slight startup time when they are not warm, so I ran the
                    tests 10 times in row per warehouse to minimize the effects of the warehouse startup times.
                     Interestingly, in the charts below you can see that the vectorized UDFs actually took longer 
                     than the vanilla python implementation in all cases. I think this is due to a combination of two things:

                   <br /> 
                   <br />

                <ol> 
                    <li> 
                    The pandas package takes time to load. Since Vectorized UDFs are called in batches, the pandas library is being loaded for each batch (1,000,000 rows in this case), adding overhead. 
                    </li>
                    <br />
                    
                    <li>
                    Snowflake does a good job of scaling row-by-row execution of vanilla python code. 
                    </li>
                </ol>

                
                <br /> 
                <br />

                    *The charts below are specifically showing execution time, and do not include any queueing time or
                     compilation time. 

                <br /> 
                <br />

                    Using the large warehouse shows a significant improvement over using a small warehouse. For 10^8 rows
                     the execution time was around 8 times faster, but for the smaller dataset sizes, there was almost no
                      difference. This reinforces the importance of sizing your warehouses optimally since the large warehouse
                       costs 4x the credits of the small warehouse. 

                </p>



                <div className="blog-pics">
                    <img src={SnowflakeSmallExec} alt = "snowflake exeuction performance, sm wh" width='100%'/>
                </div>

                <div className="blog-pics">
                    <img src={SnowflakeLargeExec} alt = "snowflake exeuction performance, lg wh" width='100%'/>
                </div>




                <p className="project-main-text" style={{display:'block'}}>
                    When we compare the UDF performance to the results on my local machine, we can see that the Snowflake 
                    UDFs scale better. However, for this testing, we didn’t reach a scale where it would be more efficient
                     to run everything on Snowflake. The test dataset size was only around 200 MB, so in this case, system
                      memory didn’t need to be taken into account. Snowflake will be able to scale to much higher memory 
                      limits than my local machine, so at a certain point using Snowflake is the only option. Also, keep 
                      in mind that the local performance will depend on your computer’s specs. Even though my computer is
                       getting old, the CPU (intel i7-8700k) has around the same performance as a MacBook pro with an m1
                        processor.
                </p>

                <div className="blog-pics">
                    <img src={SfvsLocal} alt = "snowflake exeuction performance, lg wh" width='100%'/>
                </div>



                <h2 className="project-title-text-2">
                    Non-Vectorized UDF With Pandas Compairison
                </h2>

                <p className="project-main-text" style={{display:'block'}}>
                    To show the effects of using pandas without vectorizing the functions I created a UDF that imports pandas
                     without vectorization or batching. The result was a significant increase in compute time shown in the
                      graphs below. For the largest dataset with 10^8 records, I stopped the queries early after 10 minutes 
                      of execution. Clearly, if you are already using pandas you need to vectorize your UDFs. 
                </p>


                <div className="blog-pics">
                    <img src={SfPandasSmall} alt = "snowflake exeuction performance, sm wh" width='100%'/>
                </div>

                <div className="blog-pics">
                    <img src={SfPandasLarge} alt = "snowflake exeuction performance, lg wh" width='100%'/>
                </div>




                <div className="blog-pics">
                    <img src={SfPandasSmallAll} alt = "snowflake exeuction performance, sm wh" width='100%'/>
                </div>

                <div className="blog-pics">
                    <img src={SfPandasLargeAll} alt = "snowflake exeuction performance, lg wh" width='100%'/>
                </div>

                <h2 className="project-title-text-2">
                    Vectorized Batch Size Compairison
                </h2>


                <p className="project-main-text" style={{display:'block'}}>
                I was also interested in the effects of batch size on UDF performance. Batch size seems to play a minor 
                role in performance as long as the batch size is sufficiently high, even on larger datasets. I suspect
                 as long as the batch size is small enough that it does not exceed the 60-second execution limit then
                  you probably don’t need to worry about it. Furthermore, the Snowflake docs state that the batch size
                   may not guarantee the number of rows in the batch. 
                </p>


                <div className="blog-pics">
                    <img src={SfBatchSmall} alt = "snowflake exeuction performance, sm wh" width='100%'/>
                </div>

                <div className="blog-pics">
                    <img src={SfBatchLarge} alt = "snowflake exeuction performance, lg wh" width='100%'/>
                </div>




                <p className="project-main-text" style={{display:'block'}}>
                    Takeaways:

                   <ol>

                   
                   <li>
                   If your function can run in vanilla python with decent performance then vectorizing the UDF is likely not needed
                    </li> 
                    <li>
                    Always vectorize UDFs that use external libraries like Pandas. 
                    </li>
                    <li>
                    Keep warehouse sizing in mind as using a larger warehouse has the potential to save both time and credits compared to a smaller warehouse when working with larger datasets
                    </li>
                    <li>
                    Batch size is not very important (with some caveats)
                    </li>
                    </ol>
                </p>



            </div>          
        )
    }
}

export default UDFPerformance;