import React from 'react';
import '../styles.css'
import AWS from '../codeicons/aws logo2.png'
import Azure from '../codeicons/Azure-SQL-Database.png'
import Django from '../codeicons/django-logo-negative.png'
import Docker from '../codeicons/dockerLogo.png'
import JS from '../codeicons/JS.png'
import PowerBi from '../codeicons/powerbi.svg'
import Python from '../codeicons/pythonSymbol.png'
import ReactIcon from '../codeicons/reactIcon.png'
import Sklearn from '../codeicons/sklearn.png'
import TensorFlow from '../codeicons/tensorFlow.png'
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    image: {
      maxidth: 128,
      height: 128,
    },
    img: {
      padding: '10px',
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    tools: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }

  }));


function Webicons() {
      const classes = useStyles();

  return (
    <div style = {{display: 'relative', padding: '50px'}}>
        <Grid container justify="center" spacing={3}>
            <Grid item md={6} xs={12} className={classes.tools}>
                <h1 className="tools">
                    My Tools
                </h1>
            </Grid>            
            <Grid container md={6} xs={12} className={classes.tools}>
                <Grid item md={3} xs={6} className={classes.image}>
                <ButtonBase className={classes.image}>
                    <img className={classes.img} src={Python} alt="python logo" />
                </ButtonBase>
                </Grid>
                <Grid item md={3} xs={6} className={classes.image}>
                <ButtonBase className={classes.image}>
                    <img className={classes.img} alt="django logo" src={Django} />
                </ButtonBase>
                </Grid>
                <Grid item md={3} xs={6} className={classes.image}>
                <ButtonBase className={classes.image}>
                    <img className={classes.img} src={TensorFlow} alt="tensorflow logo" />
                </ButtonBase>
                </Grid>
                <Grid item md={3} xs={6} className={classes.image}>
                <ButtonBase className={classes.image}>
                    <img className={classes.img} src={Sklearn} alt="sklearn logo" />
                </ButtonBase>
                </Grid>
                <Grid item md={3} xs={6} className={classes.image}>
                <ButtonBase className={classes.image}>
                    <img className={classes.img} src={JS} alt="javascript logo"/>
                </ButtonBase>
                </Grid>
                <Grid item md={3} xs={6} className={classes.image}>
                <ButtonBase className={classes.image}>
                    <img className={classes.img} src={ReactIcon} alt="react logo" />
                </ButtonBase>
                </Grid>
                <Grid item md={3} xs={6} className={classes.image}>
                <ButtonBase className={classes.image}>
                    <img className={classes.img} src={AWS} alt="aws logo" />
                </ButtonBase>
                </Grid>
                <Grid item md={3} xs={6} className={classes.image}>
                <ButtonBase className={classes.image}>
                    <img className={classes.img} src={Docker} alt="docker logo" />
                </ButtonBase>
                </Grid>
                <Grid item md={3} xs={6} className={classes.image}>
                <ButtonBase className={classes.image}>
                    <img className={classes.img} src={Azure} alt="azure sql logo" />
                </ButtonBase>
                </Grid>
                <Grid item md={3} xs={6} className={classes.image}>
                <ButtonBase className={classes.image}>
                    <img className={classes.img} src={PowerBi} alt="power bi logo" />
                </ButtonBase>
                </Grid>


            </Grid>
        

        </Grid>
    {/*
        <div className="section2">
            <div className="tools">
                <h1>
                    My Tools
                </h1>
            </div>
            <div className="icongrid">
                <img className="iconimage" src={Python} alt="python logo"/>
                <img className="iconimage" src={Django} alt="django logo"/>
                <img className="iconimage" src={TensorFlow} alt="tensorflow logo"/>
                <img className="iconimage" src={Sklearn} alt="sklearn logo"/>
                <img className="iconimage" src={JS} alt="javascript logo"/>
                <img className="iconimage" src={ReactIcon} alt="react logo"/>
                <img className="iconimage" src={AWS} alt="aws logo"/>
                <img className="iconimage" src={Docker} alt="docker logo"/>
                <img className="iconimage" src={Azure} alt="azure sql logo"/>
                <img className="iconimage" src={PowerBi} alt="power bi logo"/>
            </div>
        </div>
     */}
  </div>
  );
}

export default Webicons
