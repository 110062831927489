import React from 'react';
import { Card, CardContent, Typography, Grid, StylesProvider, Link } from '@material-ui/core'
import styles from './Cards.module.css';
import CountUp from 'react-countup';
import cx from 'classnames';


const WidgetCards = () => {
    
    {/*  Need this because data isn't loaded in right away, but it only takes a fraction of a second    */}


    return (
        <div className={styles.containerBottom}>

            <Grid container spacing={1} justify="space-around"> 
                <Grid item component={Card} xs={12} md={6} className={cx(styles.card, styles.USemissions)}> 
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Dashboard Description
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                            This dashboard was made using BP data pulled as a JSON file from my github account. 
                            It is built on React, Chart-JS, and Material-UI. The graphs are interactive if you click on the graphs or
                            the legends.
                            The dashboard would automatically update if I were to update the data on github because 
                            I pulled all of the data as JSON requests using AJAX.

                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                            You can take a look at the data on my 
                            <Link href="https://github.com/PrestonBlackburn/BP-datasets" target="_blank"> github account</Link>
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>

        </div>
    )
}

export default WidgetCards