import React from "react"
import Projects from './components/Projects';
import Header from './components/header';
import Home from './Home';
import Clouds from './components/clouds';
import Clouds2 from './components/clouds2';
import Webicons from './components/webicons';
import BottomBar from './components/bottombar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import ProdProject from './posts/prodproject';
import ShrinkProject from './posts/shrinkproject';
import GPAProject from './posts/2103project';
import Dashboard from './posts/Dashboard';
import PlusFractionModel from './posts/fingerprint';
import EOSMLModel from './posts/EOS_ML_Modeling';
import StyleProject from './posts/StyleProject';
import WebsiteUpdateCard from './posts/WebsiteUpdateProject';
import StockProject from './posts/StockProject';
import BeerRecProject from './posts/BeerRecProject';
import BeerRecProject2 from './posts/BeerRecProject2';
import BeerRecProject3 from './posts/BeerRecProject3';
import DataEngCensus from './posts/DataEngCensus';
import DockerDriver from './posts/DockerDriver';
import DockerGPU from './posts/DockerGPU';
import AquaponicsPost1 from './posts/AquaponicsPost1';
import SnowflakeUDF from './posts/SnowflakeUDF';
import UDFPerformance from './posts/UDFPerformance';
import SnowflakeKedro from "./posts/KedroSnowflake";
import IcePick from "./posts/IcePickPost";
import './styles.css'


export default function App() {

  return (
    <Router> 
      <Header />

        <Switch>
          <Route path="/projects" exact component={Projects} />
          <Route path="/projects/2103project" exact  component={GPAProject} />
          <Route path="/projects/prodproject" exact component={ProdProject} />
          <Route exact path="/projects/shrinkproject" component={ShrinkProject} />
          <Route path="/projects/Dashboard" exact component={Dashboard} />
          <Route path="/projects/plusfractionmodel" exact component={PlusFractionModel} />
          <Route path="/projects/EosModeling" exact component={EOSMLModel} />
          <Route exact path="/projects/StyleProject" component={StyleProject} />
          <Route exact path="/projects/WebsiteUpdateProject" component={WebsiteUpdateCard} />
          <Route exact path="/projects/StockProject" component={StockProject} />
          <Route exact path="/projects/RecommendationProject" component={BeerRecProject} />
          <Route exact path="/projects/RecommendationProjectPipeline" component={BeerRecProject2} />
          <Route exact path="/projects/BeerRecommendationSite" component={BeerRecProject3} />
          <Route exact path ="/projects/CensusDataEngPipeline" component={DataEngCensus} />
          <Route exact path ="/projects/AzureDataTransfer" component={DockerDriver} />
          <Route exact path ="/projects/GpuWithDocker" component={DockerGPU} />
          <Route exact path="/projects/AquaponicsProject1" component={AquaponicsPost1} />
          <Route exact path = "/projects/SnowflakeUDF" component={SnowflakeUDF} />
          <Route exact path="/projects/UDFPerformance" component = {UDFPerformance} />
          <Route exact path="/projects/KedroSnowflake" component = {SnowflakeKedro} />
          <Route exact path="/projects/IcePick" component = {IcePick} />
          <Route path="/" exact component={Home}/>
        </Switch>
    </Router>
  )
}