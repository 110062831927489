import React, { Component } from 'react';

import IcePickLogo from '../images/IcePick/ice_pick_logo_mountain.png';
import IcePickDocs from '../images/IcePick/read_the_docs_ice_pick.png';

class IcePick extends Component {
    render() {
        return(
            <div className="project-box">
                <h1 className="project-title-text">
                    Extending Snowpark - Ice Pick Library 
                </h1>
                <p className="project-main-text" style={{display:'block'}}>
                    On a project I found myself creating a script for extracting DDL for various Snowflake objects for the 3 or 4th time.
                    Currently, extracting the DDL with python is a somewhat clunky process, where you'll need to use Snowpark to execute SQL
                    statements since there is no native Snowpark support to get metadata like the DDL of an object. 
                    However, by extending the Snowpark library we can add additional functionality, such as getting ddl,
                    that has a “native” Snowpark feel. This is what I've attempted to achieve with the ice pick library. 
                    The two key challenges to creating the extension are giving the extension a native feel, and finding 
                    the appropriate abstractions for the functionality I wanted to add.
                         
                </p>

                <p className="project-main-text" style={{display:'block'}}>
                Ice pick docs:
                <br />
                <a href="https://ice-pick.readthedocs.io/en/latest/notebooks/tutorial.html" target="_blank" style={{textDecoration:"none", }}>
                    https://ice-pick.readthedocs.io/en/latest/notebooks/tutorial.html
                 </a> 
                 <br /> 
                   <br />
                   Ice pick repo:
                <br />
                <a href="https://github.com/PrestonBlackburn/ice_pick" target="_blank" style={{textDecoration:"none", }}>
                    https://github.com/PrestonBlackburn/ice_pick
                 </a> 
                </p>
                
                <h2 className="project-title-text-2">
                    Snowpark background
                </h2>
                <p className="project-main-text" style={{display:'block'}}>
                Snowpark has a lot of great functionality built-in, and allows users easily interact with Snowflake using python (and other languages).
                 With Snowpark, you can push down compute to Snowflake to reduce data transfer costs. DataFrames make up the core of Snowpark, 
                 and represent a set of data and methods to operate on that <b>data</b>. 
                <br/>
                While Snowpark is focused on data manipulation, ice pick extends Snowpark to provide support for manipulating <b>snowflake objects</b>. 
                In the case of a table object, base Snowpark manipulates the data in a table, and 
                the ice pick extension helps get and manage metadata about that table.

                </p>


                <h2 className="project-title-text-2">
                Extending The Snowpark Session
                </h2>
                <p className="project-main-text" style={{display:'block'}}>
                To integrate the library with Snowpark and give it a native feel, I decided the most natural approach was to extend the 
                Snowpark Session class. By extending the Session, ice pick functions can be called the same 
                Snowpark functions are called. 
                <br/>
                <br/>
                For Example
                <br/>
                <br/>
                In Snowpark to create a DataFrame object instance:
                <br/>
                <code> df = session.table("TEST.SCHEMA_1.CUSTOMER") </code>
                <br/>
                <code> results = df.collect() </code>
                <br/>
                <br/>
                In Ice Pick to create a SchemaObject instance:
                <br/>
                <code> table_obj = session.create_schema_object('TEST', 'SCHEMA_1', 'CUSTOMER', 'TABLE') </code>
                <br/>
                <code> ddl = table_obj.get_ddl() </code>
                <br/>
                <br/>
                In both cases, the session is used to create an instance of an object. To extend the Snowpark session I used a technique called monkey patching. 
                Monkey patching allows you to dynamically change or add a behavior to code at run-time. The upsides to this approach are that it gives the extension
                 a native feel since we are actually adding methods to the Snowpark Session. However, there are a few downsides including, that it can be dangerous 
                 if fundamental changes are made to the Snowpark Session class, and that it may be better to make changes upstream in the Snowpark library itself. 
                 For the second point, eventually, it would be nice to update Snowpark to include this functionality instead of relying on an extension, but a lot 
                 of the additional functionality may be out of scope for Snowpark. Snowpark is focused on providing an alternative to Pyspark, and adding support 
                 for Schema Objects and higher-level functions does not fit within that scope (my thoughts, not those of Snowflake).
                </p>


                <h2 className="project-title-text-2">
                    Ice Pick Abstractions
                </h2>
                <p className="project-main-text" style={{display:'block'}}>
                So far the two main classes I've added are the SchemaObject and the SchemaObjectFilter.
                 The SchemaObject represents an object that lives in a Snowflake Schema. Generally schema 
                 level objects have similar rules (with a few exceptions), 
                 so they will be interacted with in the same way. Snowflake references many of these
                  objects as “database” objects (see &nbsp;                
                  <a href="https://docs.snowflake.com/en/sql-reference/sql/desc" target="_blank" style={{textDecoration:"none", }}>
                    snowflake docs reference
                 </a> 
                ),
                but I chose to separate out schema objects to preserve a common path format amongs the schema objects. 
                Database objects also include schemas which do not follow the naming convention of all other schema objects 
                - "database.schema.object". 


                <br/>
                The SchemaObjectFilter exists to return specific or many SchemaObjects. This makes it convenient to get
                 bulk properties of SchemaObjects such as ddl, grants, descriptions, etc… now with a couple lines of 
                 code you can retreive the ddl for all schema level objects in a snowflake account.

                </p>



                <h2 className="project-title-text-2">
                   Example Usage
                </h2>
                <p className="project-main-text" style={{display:'block'}}>
                Along with building the ice pick library I also wanted to work on my code documentation. 
                For documentation I've create a read the docs page using Sphinx: &nbsp;                 
                  <a href="https://ice-pick.readthedocs.io/en/latest/" target="_blank" style={{textDecoration:"none", }}>
                    Ice Pick - Read The Docs Page
                 </a> 


                </p>

                <div className="blog-pics">
                    <img src={IcePickDocs} alt = "read the docs example" width='100%'/>
                </div>




                <p className="project-main-text" style={{display:'block'}}>
                    I'd highly recommend checking out this youtube video if you are interesting in learning more about getting started with Sphinx:
                    <a href="https://www.youtube.com/watch?v=qRSb299awB0" target="_blank" style={{textDecoration:"none", }}>
                    Document Your Scientific Project With Markdown, Sphinx, and Read the Docs | PyData Global 2021
                 </a> 
                </p>


                <h2 className="project-title-text-2">
                    Final Thoughts
                </h2>
                <p className="project-main-text" style={{display:'block'}}>
                    I plan on continue building out ice pick to improve my Snowflake development experience
                    (and hopefully other peoples!). I'm working on adding some higher level functions, 
                    such as an “auto_union” function to make it easier to union tables with different and 
                    overlapping columns. If you have any ideas or want to contribute feel free to reach out
                    or create a pull request!
                </p>



            </div>          
        )
    }
}

export default IcePick;