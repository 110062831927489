import React, { Suspense, useRef, useMemo } from "react"
import { Canvas, useFrame } from "@react-three/fiber"
import { ContactShadows, OrbitControls, Html } from "@react-three/drei"
import Media from 'react-media';

import Overlay from './components/Overlay'
import MobileOverlay from './components/MobileOverlay'
import Header from './components/header'
import Clouds from './components/clouds'
import Clouds2 from './components/clouds2'
import Webicons from './components/webicons'
import BottomBar from './components/bottombar'

const HTMLContent = () => {
  return(
      <Html center zIndexRange={[2, 0]}>
        <Overlay />
      </Html>
  )
}

const MobileHTMLContent = () => {
  return(
      <Html center zIndexRange={[2, 0]}>
        <MobileOverlay />
      </Html>
  )
}


export default function Home() {

  return (
    <>
    <Canvas camera={{ position: [0, -75, 0] }} style={{ background: 'linear-gradient(to bottom, #CCCCCC, #19191F)', width: '100%', height: '100%', margin: 0, padding: 0 }}>
      <ambientLight intensity={0.8} />
      <pointLight intensity={2} position={[0, 0, -1000]} />
      <Suspense fallback={null}>
      <Media queries={{ small: { maxWidth: 599 } }}>
        {matches => 
          matches.small ? (
            <MobileHTMLContent />
          ) : (
            <HTMLContent />
          )}

      </Media>
        <Clouds style={{ backgroundColor: 'linear-gradient(to bottom, #CCCCCC, #19191F)', width: '100%', height: '100%', margin: 0, padding: 0 }}/>
      </Suspense>
      <ContactShadows opacity={1} width={1} height={1} blur={1} far={10} resolution={256} />
    </Canvas>
    <Webicons />
    <Canvas style={{height: '50vh', background: 'linear-gradient(to bottom, #CCCCCC, #19191F)' }} camera={{ position: [0, -75, 0] }}>
      <ambientLight intensity={0.8} />
      <pointLight intensity={2} position={[0, 0, -1000]} />
      <Suspense fallback={null}>
        <Clouds2 />
      </Suspense>
      <OrbitControls minPolarAngle={Math.PI / 1.5} maxPolarAngle={Math.PI / 1.5} />
      <ContactShadows opacity={1} width={1} height={1} blur={1} far={10} resolution={256} />
    </Canvas>
    <BottomBar />
    </>
  )
}