import React, { Component } from 'react';

import PipelineOverview from '../images/DataEngCensus/data_pipeline_diagram.png';
import CreateDatabase from '../images/DataEngCensus/create_database.png';
import S3ToSF from '../images/DataEngCensus/data-load-bulk-s3.png';
import SnowflakeExtStage from '../images/DataEngCensus/snowflake_ext_stage.png';
import AirflowWebserverPage from '../images/DataEngCensus/airflow_webserver_page.png';
import DAG1 from '../images/DataEngCensus/DAG1.png';
import DAG2 from '../images/DataEngCensus/DAG2.png';
import AirflowSagemaker from '../images/DataEngCensus/airflow pipeline execution.png';
import TSNE from '../images/DataEngCensus/tsne chart 2.png'; 
import TSNEWorst from '../images/DataEngCensus/worst places to live.png'; 

class DataEngCensus extends Component {
    render() {
        return(
            <div className="project-box">
                <h1 className="project-title-text">
                    Airflow Data Engineering Project
                </h1>
                <h2 className="project-title-text-2">
                    Creating  A Pipeline For Preparing US Census Data For An Exploratory Data Analysis
                </h2>
                <p className="project-main-text" style={{display:'block'}}>

                Data engineering is essential to prepare data to be used by machine learning models. Without clean and quality data machine learning can't be used 
                to gain insights from data. In this project I set up data pipelines to automatically prepare US census data for analysis. 
                <br />
                <br />
                My goals for this project were to get familiar with airflow and set up an ELT (extract load transform) pipeline to prepare US census data for analysis.
                 For the data analysis I wanted to start to explore if the “best” and “worst” places in the us to live had underlying similarities, and to find great 
                 undiscovered city/towns.
                <br />
                <br />
                I created this project alongside reading the "Data Pipelines Pocket Reference" book by James Densmore. The book has a great overview of how to
                 get started with data pipelines and reviews many standard tools used in data pipelines. In my project, I went more in depth into 
                 the book's topics, such as Airflow.
                 <a href="https://www.amazon.com/Data-Pipelines-Pocket-Reference-Processing/dp/1492087831" target="_blank" style={{textDecoration:"underline", }}> Link To Book</a> 
                <br />
                <br />
                My pipeline followed the ELT pattern. I used Amazon Aurora DB as the data source then used Airflow 
                to extract and load the data into a Snowflake database. After the data was loaded into Snowflake I performed transformations using Airflow
                 and a Amazon Sagemaker processing job. I used the Sagemaker processing job to perform a t-sne decomposition to try and gain insights into 
                 underlying similarities between towns and cities. Once the data was transformed, I loaded the t-sne results from Snowflake into Power BI 
                 for analysis. 
                <br />
                <br />


                All of the code for this project is on my GitHub: &nbsp;
                <a href="https://github.com/PrestonBlackburn/first-airflow-data-pipeline" target="_blank" style={{textDecoration:"none", }}> Project Code</a>   

                <br />
                <br />
                <br />
                    The architecture diagram is shown below. 

                </p>


                <div className="blog-pics">
                    <img src={PipelineOverview} alt = "archetecture diagram for pipeline" width='90%'/>
                </div>
                
                <h2 className="project-title-text-2">
                    Walkthrough
                </h2>

                <p className="project-main-text" style={{display:'block'}}>
                    First off, pull any US census data you want from NHGIS in csv format. 
                    You may need to create an account to access the data. For this project I pulled all of the 36,000 of the US towns and cities from the database along with data from the 2019 American Community Survey. 
                    <br />
                    <br />
                    Next, we need to create a database to hold the NHGIS data to mimic a production system. I used Amazon Aurora as 
                    the database and created the resources through the AWS console. Since AWS Aurora is fully managed it is
                    easy to spin up a MySQL compatible database. I won't go into much detail about starting up the database 
                    <br />
                    <br />
                    Note: you can only connect to the serverless version through a VPC, so I stuck with 
                    the provisioned database with the db.t3.small burstable instance. Leaving this database
                     up for a month will cost about $25.
                </p>

                <div className="blog-pics">
                    <img src={CreateDatabase} alt = "Create Model" width='80%'/>
                </div>

                <p className="project-main-text" style={{display:'block'}}>
                    Once the database I created we need to create the tables for the US census data. I add two tables,
                     one for the census demographic data table and another for the metadata for the census demographic
                     ata. I stored the descriptions of the column names and data types in the metadata table so they 
                     could be easily used later. 

                     <br />
                     You can view the code I used to create and populate the tables on GitHub: &nbsp;
                <a href="https://github.com/PrestonBlackburn/first-airflow-data-pipeline/tree/main/Table%20Creation%20Github" target="_blank" style={{textDecoration:"underline", }}> Creating Aurora DB Tables</a>  
                <br />
                <br />
                 Now that the data is in the database we can start setting up our data pipeline. To start we will create a s3
                  bucket that will act as an external stage for snowflake. Then we can create the data warehouse in snowflake
                   that the data will be copied into. We'll create the table later using an airflow task after some data processing.
                </p>

                <div className="blog-pics">
                    <img src={S3ToSF} alt = "Snowflake and S3 Stage" width='80%'/>
                </div>

                <p className="project-main-text" style={{display:'block'}}>
                Once the s3 bucket is created using the AWS console we can head over to the snowflake console to add the external stage.
                 Snowflake makes it easy to add the external stage. First we can create a storage integration for s3 then the s3 stage can be created.
                  It only takes a few lines of code.
                </p>

                <div className="blog-pics">
                    <img src={SnowflakeExtStage} alt = "Snowflake and S3 Stage Code" width='80%'/>
                </div>

                <p className="project-main-text" style={{display:'block'}}>
                Finally, we can start setting up airflow for the data transformations. 
                If you are familiar with docker I would recommend the docker compose setup. 
                <br />
                This 5-minute setup video is great to help get you started with setting up airflow v2.0: &nbsp;
                <a href="https://www.youtube.com/watch?v=aTaytcxy2Ck" target="_blank" style={{textDecoration:"underline", }}> Airflow Docker Setup YouTube </a>  
                </p>
                

                <div className="blog-pics">
                    <img src={AirflowWebserverPage} alt = "Airflow View" width='95%'/>
                </div>



                <h2 className="project-title-text-2">
                    Airflow DAGs
                </h2>

                <p className="project-main-text" >
                    In airflow I created two DAG (directed acyclic graphs) one for the initial EtL and one for the final data transformation.
                     The DAGs chain together tasks to create data pipelines. 
                </p>

                <h2 className="project-title-text-2" style={{display:'block', }}>
                    DAG 1
                </h2>


                <div className="blog-pics">
                    <img src={DAG1} alt = "Airflow View" width='80%'/>
                </div>

                <p className="project-main-text" style={{display:'block'}}>
                    In the data extraction step, I read all the data from the Aurora database and
                     load it into an S3 bucket that is an external stage for my Snowflake database. 
                     <br />
                     <br />
                     Before uploading the data to snowflake, I perform a few simple transformations. First,
                      I updated the column names from the codes given by NHGIS to their descriptions, so 
                      they are easier to understand. Then I removed the columns with no data in them and
                       deleted any duplicate columns. 
                       <br />
                       <br />
                       In the load step I load the data in the s3 bucket to snowflake. Since the S3 bucket was 
                       already set up as an external stage, all I need to do is run the COPY INTO command. 
                       <br />
                       <br />
                       <a href="https://github.com/PrestonBlackburn/first-airflow-data-pipeline/blob/main/dags/rds_tables_to_s3.py" target="_blank" style={{textDecoration:"underline", }}> Code for DAG 1 </a> 
                </p>


                <p className="project-main-text" style={{display:'block'}}>
                    Before creating the second DAG we need to create the Sagemaker processing job that will run the t-sne decomposition.
                    The second DAG will trigger the processing job and wait for the results. I put the Sagemaker processing job into 
                    a Sagemaker pipeline to be able to easily trigger it using boto3 from airflow. 
                    <a href="https://github.com/PrestonBlackburn/first-airflow-data-pipeline/tree/main/Sagemaker" target="_blank" style={{textDecoration:"underline", }}> Sagemaker Processing Code </a>  
                </p>

                <div className="blog-pics">
                    <img src={AirflowSagemaker} alt = "Airflow View" width='90%'/>
                </div>

                <h2 className="project-title-text-2" style={{display:'block', }}>
                    DAG 2
                </h2>


                <div className="blog-pics">
                    <img src={DAG2} alt = "Airflow View" width='90%'/>
                </div>

                <p className="project-main-text" style={{display:'block'}} >
                In the second DAG I perform a t-sne decomposition on the data to create a new table. T-sne allows
                    us to take high dimensional data and project it onto two dimensions. By projecting the data 
                    into two dimensions humans can more easily look for groupings or similar locations. 
                    <br />
                    <br />
                    I call the sagmaker pipeline in the first airflow task and wait for it to finish.
                    Once the processing job has finished I push the results back to Snowflake in a separate table. 
                    <br />
                    <a href="https://github.com/PrestonBlackburn/first-airflow-data-pipeline/blob/main/dags/tsne_to_sf.py" target="_blank" style={{textDecoration:"underline", }}> Code for DAG 2 </a>
                    <br />
                    <br />
                        After all that data processing we can finally pull the data into a visualization tool like Power BI.
                        Originally, I used AWS Quicksight, but I ran into performance issues and switched to Power BI. 
                    <br />
                        Some visualizations of the t-sne decompositions in power bi are shown below. The data is 
                        much more interesting when you can interact with it in Power BI, but I tried to pull 
                        out some examples from my analysis. 
                </p>



                <h2 className="project-title-text-2" style={{display:'block', }}>
                    Results
                </h2>

                <p className="project-main-text" style={{display:'block'}}>
                    T-sne chart of all 36,000 cities and towns in the census. Some groupings emerge, but overall it is relatively evenly distributed-
                </p>

                <div className="blog-pics">
                    <img src={TSNE} alt = "Airflow View" width='90%'/>
                </div>

                <p className="project-main-text" style={{display:'block'}}>
                    The t-sne chart with the “worst” places to live (according to some random internet list) selected is shown below. 
                    There may be some lose grouping of cities and towns that were deemed not desirable to live in by the internet. Most of the towns or cities have smaller populations, home prices, etc.. which may contribute to their grouping. 
                </p>

                <div className="blog-pics">
                    <img src={TSNEWorst} alt = "Airflow View" width='90%'/>
                </div>

                <p className="project-main-text" style={{display:'block'}}>
                    If you are interested with using t-sne in your projects I would recommend checking out
                     TensorFlow projectors (<a href="https://projector.tensorflow.org/" target="_blank" style={{textDecoration:"underline", }}> TensorFlow Projectors </a>),
                      which allows you to upload data to be analyzed via t-sne or PCA. 
                </p>

                <p className="project-main-text" style={{display:'block'}}>
                    Since the t-sne decomposition didn't produce strong groupings, I think it would be interesting to use a knn algorithm to return the top 10
                    locations that are closest to each other demographically, but that is a pipeline for another day. 
                </p>

            </div>          
        )
    }
}

export default DataEngCensus;