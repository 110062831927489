import React  from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import MLStyleProd  from '../../images/MLInProd/stary_gatsby2.jpg';


const useStyles = makeStyles((theme) => ({

    heroContent: {
      backgroundColor: '#F6F6F6',
      padding: theme.spacing(8, 0, 6),
      paddingTop: '150px',
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    heroButtonStyle: {
      background: '#f44336',
      color: 'white',
      '&:hover': {
        backgroundColor: '#ff7961',
  
    },
    },
    heroButton2Style: {
      background: 'white',
      color: '#f44336',

    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    dateContent: {
      fontSize: '14px',
      padding: '2px',
      color: 'grey',
      paddingLeft: '20px',
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

function StyleTransferCard() {
    const classes = useStyles();
  return(

            <Grid item key={2} xs={12} sm={6} md={4}>
            
            <Card className={classes.card}>
            <a className="linkclass" href="/projects/StyleProject"  color="black">
              <CardActionArea>
              <CardMedia
                className={classes.cardMedia}
                image={MLStyleProd}
                title="Web dev style transfer project"
              />
              </CardActionArea>
            </a>
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  Arbitrary Style Transfer Web App
                </Typography>
                  <Typography>
                    "Instant Artist" is a full stack Django/React/NGINX/Docker app deployed on AWS. Uses machine learning to "style" user uploaded images.
                  </Typography>
                </CardContent>
                <Typography className={classes.dateContent}>
                  5/4/21
                </Typography>                

              <CardActions>
              <a href="/projects/StyleProject" style={{textDecoration: 'none',}}>
                <Button size="small" className={classes.heroButton2Style}>
                  View
                </Button>
                </a>
                <a href="http://artist.prestonblackburn.com/" target="_blank" style={{textDecoration: 'none',}}>
                <Button size="small" className={classes.heroButton2Style}>
                  Site
                </Button>
                </a>
              </CardActions>
            </Card>
          </Grid>

  )}
export default StyleTransferCard