import React, { Component } from 'react';

import WorkflowOverview from '../images/DockerDriver/sqltos3lambda.png';

class DockerDriver extends Component {
    render() {
        return(
            <div className="project-box">
                <h1 className="project-title-text">
                    Automate Pulling Data From Azure SQL Server The Easy Way

                </h1>
                <h2 className="project-title-text-2">
                        Transfer data from an Azure SQL Server using AWS Lambda for light workloads 
                </h2>
                <p className="project-main-text" style={{display:'block'}}>

                    Pulling small to medium datasets from a SQL server in Azure to an AWS S3 seems like it should be a 
                    trivial task with an AWS lambda function. However, a connection to the SQL Server requires an ODBC 
                    driver to be set up on the lambda function, which adds some complexity. In this post, I'll go over
                     the easiest way to set this driver up, and hopefully save you some time on google. 
                <br />
                <br />
                    There are two options for deploying a lambda function environment, using a 
                    zip package or a docker container. I prefer docker, so that's what I'll use
                     for this post. The easiest way to set up the driver for a lambda function
                      is to pull a docker image with the driver already installed and then add
                       any additional requirements. I've created a python 3.9 docker image with
                        the driver pre-installed on docker hub, so you can use the pre-made image
                         directly by following the dockerfile in this repo: &nbsp;
                 <a href="https://github.com/PrestonBlackburn/aws-lambda-odbc-driver/tree/main/lambda_usage_example" target="_blank" style={{textDecoration:"underline", }}> Github Repo</a> 
                <br />
                <br />
                The docker image on docker hub:  &nbsp;
                <a href="https://hub.docker.com/r/prestonbl/aws-lambda-python-with-sqlserver-odbc-driver" target="_blank" style={{textDecoration:"underline", }}> Dockerhub Repo</a> 
                <br />
                <br />
                You can also create your own docker image if you need to use a different version of python.
                 The dockerfile for creating the docker image can be found here:  &nbsp;
                <a href="https://github.com/PrestonBlackburn/aws-lambda-odbc-driver/tree/main/pyodbc_sql_server_driver_dockerfile" target="_blank" style={{textDecoration:"underline", }}> Base Dockerfile</a> 
                <br />
                </p>


                <div className="blog-pics">
                    <img src={WorkflowOverview} alt = "Simple arch diagram" width='90%'/>
                </div>

                <p className="project-main-text" style={{display:'block'}}>
                    Now that the ODBC driver is installed on the lambda function, we'll be able to use the python
                     package “pyodbc” to connect to the Azure SQL Server. Once you retrieve the desired data from
                      Azure SQL Server, I use pandas to save it to an S3 bucket. This process can be triggered on
                       a schedule using EventBridge, which can be set up in the AWS console or in a few lines of 
                       code in a SAM template. 
                </p>


                <h2 className="project-title-text-2">
                    Next Steps
                </h2>

                <p className="project-main-text" style={{display:'block'}}>
                I would highly recommend using AWS SAM to set up your lambda functions. After you complete
                 the initial SAM setup you can follow along with the hello world tutorial and then substitute
                  the code for the requirements.txt, dockerfile, and appy.py files with the code in this repo: &nbsp;
                <a href="https://github.com/PrestonBlackburn/aws-lambda-odbc-driver/tree/main/lambda_usage_example" target="_blank" style={{textDecoration:"underline", }}> Example Usage</a> 
                <br />
                <br />
                Once the data is in S3 it can be ingested by a number of other services. For example, an external 
                stage can be set up in Snowflake on the S3 bucket and the data can be automatically updated/loaded. 

                </p>



                
                <h2 className="project-title-text-2">
                    Notes
                </h2>

                <p className="project-main-text" style={{display:'block'}}>
                    This method will work for datasets up to about 10 GB, which is the max size of the memory of a lambda function. 
                <br />
                <br />
                    Shout out to Camilo Soto Montoya for this post that provides the original dockerfile code: &nbsp;
                <a href="https://medium.com/globant/connecting-to-sql-server-from-aws-lambda-docker-container-b474727522eb" target="_blank" style={{textDecoration:"underline", }}> Medium Post</a> 
                <br />
                <br />
                    Hopefully this saves someone an hour or two of googling!

                </p>

            </div>          
        )
    }
}

export default DockerDriver;