import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import '../styles.css'
import Githublogo from '../codeicons/GitHubdark.png'
import Linkedinlogo from '../codeicons/LI-In.png'


function BottomBar() {


  return (
    <div >
        <div className="bottombar">
            <a href="http://www.linkedin.com/in/prestonblackburncheme" rel="noopener noreferrer" target="_blank">
                <img style={{width: '100px', paddingLeft:'50px'}} src={Linkedinlogo} aria-hidden="true" />
            </a>
            <a href="https://github.com/PrestonBlackburn" rel="noopener noreferrer" target="_blank">
                <img style={{width: '100px', paddingLeft:'50px'}} src={Githublogo} aria-hidden="true" /> 
                </a>
        </div>
  </div>
  );
}

export default BottomBar
