import React, { Component } from 'react';
import chartLong from '../images/2103Post/process flow chart long.png';
import chartShort from '../images/2103Post/process flow chart short.png';
import shapMW from '../images/2103Post/XGB_SHAP_MW.png';
import shapRD from '../images/2103Post/XGB_SHAP_SG.png';
import ensembleModel from '../images/2103Post/Ensemble model.png';
import predictionGraphs from '../images/2103Post/predictions_subplots_all.png';
import summaryTable from '../images/2103Post/Performance Summary Table.jpg';
import caseStudyTable from '../images/2103Post/case study.png';
import uncertaintyGraphs from '../images/2103Post/MDN_error_all.png';




class GpaProject extends Component {
    render() {
        return(
            <div className="project-box">
                <h1 className="project-title-text">
                    Machine Learning Assisted Analysis of Condensate Mixtures
                </h1>

                <p className="project-secondary-text">
                Some liquid analyses in the oil and gas industry can be improved or shortened using machine learning.
                 One such test is GPA 2013 – an analysis used to determine the composition oil along with properties such as molecular weight
                  and relative density. Multiple analyses are needed to provide all the necessary information, and this is where machine learning
                   can come in and shorten the process. We can eliminate an extra analysis that usually takes around 30 minutes by modeling it based 
                   on information taken from the other analyses. In fact, 3 out of the 4 analyses of this test can be modeled by a single analysis 
                   with only slight losses in accuracy. 
                </p>
                <p className="project-secondary-text">
                In my first model I look at the results with only replacing the 30-minute gas chromatograph (GC) analysis, and in the second I
                 model all the needed results based of only one analysis. By modeling these results the test time of GPA 2103 can be reduced by
                  30-60%. Furthermore, this is one of the most common tests done on condensate and crude oils, which means this model could save
                   companies tens of thousands of hours of test time per year.  
                </p>

                <div className="blog-pics">
                        <br></br>
                        Model 1 Applied to GPA 2103 Process
                        <br></br>
                        <br></br>
                        <img src={chartLong} alt="GPA-2103-process-long" width='90%'/>
                </div>

                <div className="blog-pics">
                    <br></br>
                    Model 2 Applied to GPA 2103 Process
                    <br></br>
                    <br></br>
                    <img src={chartShort} alt="GPA-2103-process-short" width='40%'/>
                </div>

                <p className="project-secondary-text">
                    Since I am modeling the values, I am not limited by what is possible with current laboratory equipment. In 
                    current GPA 2103 process unpressurized relative density and molecular weight are measured, but the needed
                    values are pressurized relative density and molecular weight. This is also the reason a second GC test is 
                    required. 
                </p>

                <h2 className="project-title-text-2">
                    Brief Data and Model Overview
                </h2>

                <p className="project-secondary-text">
                    If you are interested in learning more about the data used for this model and the processes I used to explore
                     and validate the dataset, please reach out to me on LinkedIn. For the sake of keeping this post to a reasonable
                      length I will only go through my modeling process and the results. 
                </p>
                <p className="project-secondary-text">
                    Using SHAP (Shapley Additive exPlanations) I found that the unpressurized molecular weight and relative 
                    density were important to modeling the pressurized molecular weight and relative density as you would
                     expect. That is why I chose to create two models, one without the unpressurized measurements and one 
                     including them. The SHAP plots are shown below.
                </p>

                <div className="blog-pics">
                    <br></br>
                    Feature Selection - Molecular Weight
                    <br></br>
                    <br></br>
                    <img src={shapMW} alt="shap-plot-mw" width='80%'/>
                </div>

                <div className="blog-pics">
                    <br></br>
                    Feature Selection - Relative Density
                    <br></br>
                    <br></br>
                    <img src={shapRD} alt="shap-plot-rd" width='80%'/>
                </div>

                <p className="project-secondary-text">
                    To create the model, I used a stacked ensemble architecture shown in the image below. Linear models,
                    such as linear support vector machines, fit the data poorly, so I left them out of my ensemble. The
                    random forest model and gradient boosting model were taken from Scikit Learn, and the neural networks 
                    were created using the Keras API for TensorFlow. I added the XGBoost and LightGBM gradient boosting models
                    because of their well know high-performance. The model architecture is shown below. 
                </p>

                <div className="blog-pics">
                    <br></br>
                    Ensemble Model Design
                    <br></br>
                    <br></br>
                    <img src={ensembleModel} alt="model archetecture" width='80%'/>
                </div>

                <h2 className="project-title-text-2">
                    Model Results
                </h2>

                <p className="project-secondary-text">
                    The results of the models for predicting pressurized molecular weight and relative density are shown 
                    below. As expected, the models without measured molecular weight and relative density performed slightly 
                    worse. However, the decrease in accuracy still might be acceptable given the decreased analysis time.
                </p>

                <div className="blog-pics">
                    <br></br>
                    Modeled Values vs Measured Values
                    <br></br>
                    <br></br>
                    <img src={predictionGraphs} alt="modeled vs measured values" width='80%'/>
                    <br></br>
                    <br></br>
                    (a) Model 1 molecular weight prediction accuracy. (b) Model 2 molecular weight prediction accuracy.
                    (c) Model 1 relative density prediction accuracy. (d) Model 2 relative density prediction accuracy.
                    The summary table below shows how the performance of the two model’s compare. Now that the models 
                    have been created, they can be applied to GPA 2103.        
                </div>

                <div className="blog-pics">
                    <br></br>
                    Summary Table
                    <br></br>
                    <br></br>
                    <img src={summaryTable} alt="summary table" width='80%'/>
                </div>

                <p className="project-secondary-text">
                    An alternative to measuring molecular weight in GPA 2103 is to use the Cragoe correlation, which uses a 
                    correlation to predict unpressurized molecular weight based on measured unpressurized specific gravity. 
                    In the table below the results from the models are compared to GPA 2103 when using the Cragoe correlation
                     for a sample. From those results the models perform similarly, if not better, than using the Cragoe
                      correlation. Furthermore, the time savings are much greater when using the machine learning models 
                      compared to the Cragoe correlation.  
                </p>

                <div className="blog-pics">
                    <br></br>
                    Case Study Comparison
                    <br></br>
                    <br></br>
                    <img src={caseStudyTable} alt="Case Study" width='80%'/>
                </div>

                <p className="project-secondary-text">
                    I was also interested in looking at the uncertainty associated with the models to see if there are any 
                    samples that the models should not be applied to.  To analyze uncertainty, I used negative logarithmic 
                    loss function to the meta model. With the negative log loss function both the mean and variance can be 
                    produced using maximum likelihood methods.  The resulting plot of standard deviation vs component values
                    are shown below. 
                </p>

                <div className="blog-pics">
                    <br></br>
                    Uncertainty vs Predicted Values
                    <br></br>
                    <br></br>
                    <img src={uncertaintyGraphs} alt="Uncertainties" width='80%'/>
                    <br></br>
                    <br></br>
                    (a) Model 1 molecular weight compared to predicted standard deviation. (b) Model 2 molecular weight
                     compared to predicted standard deviation. (c) Model 1 relative density compared to predicted standard
                      deviation. (d) Model 2 relative density compared to predicted standard deviation.
                </div>

                <h2 className="project-title-text-2">
                    Conclusion
                </h2>

                <p className="project-secondary-text">
                    Implementing these models saves a lot of analysis time with minimal accuracy loss. As the oil and gas
                     industry continues to push for increased efficiency, more time saving measures such as the one described
                      in this post will become necessary.  
                </p>

                <p className="project-main-text" padding='2rem' style={{flexDirection:'column'}}>
                    Please feel free to contact me with any questions or comments on LinkedIn: &nbsp;
                    <a href="https://www.linkedin.com/in/prestonblackburncheme" target="_blank">  Preston's LinkedIn Page </a>  
                </p>



                <h2 className="project-title-text-2">
                    References
                </h2>

                <div className="references">
                    <p className="ref-strings">
                    Bergstra, J., Yamins, D., Cox, D. D. 2013. "Hyperopt: A Python Library for Optimizing the Hyperparameters of Machine Learning Algorithms." Proceedings of the 12th Python in science conference. Citeseer. 20. doi:http://dx.doi.org/10.1.1.704.3494.
                    <br/>
                    <br/>
                    Bishop, C. M. 1994. "Mixture Density Networks." https://publications.aston.ac.uk/id/eprint/373/1/NCRG_94_004.pdf.
                    <br/>
                    <br/>
                    Breiman, L. 1996. "Bagging Predictors." Machine Learning (24) 123-140. doi:https://doi.org/10.1023/A:1018054314350.
                    <br/>
                    <br/>
                    Breiman, L. 2001. "Random Forests." Machine Learning 45(1) 5-32. doi:http://dx.doi.org/10.1023/a:1010933404324 .
                    <br/>
                    <br/>
                    Chen, T., and Guestrin, C. 2016. "XGBoost: A Scalable Tree Boosting System." In Proceedings of the 22nd ACM SIGKDD International Conferance on Knowledge Discovery and Data Mining. New York, NY. 785-794. doi:https://doi.org/10.1145/2939672.2939785.
                    <br/>
                    <br/>
                    Cragoe, C. S. 1929. Thermodynamic properties of petroleum products. Bureau of Standards, U.S. Department of Commerce, Miscellaneous Publications No. 97.
                    <br/>
                    <br/>
                    Friedman, J. H. 2001. "Greedy Function Approximation: A Gradient Boosting Machine." Annals of statistics 1189-1232. doi:http://dx.doi.org/10.1214/aos/1013203451.
                    <br/>
                    <br/>
                    Geron, A. 2019. Hands-on Machine Learning with Scikit-Learn and TensorFlow: Concepts, Tools, and Techniques to Build Intelligent Systems. Sebastopol, CA: O'Reilly Media.
                    <br/>
                    <br/>
                    Hornik, K., Stinchcombe, M., and White, H. 1989. "Multilayer Feedforward Networks are Universal Approximators." Neural Networks, Vol 2: 359-366. doi:http://dx.doi.org/10.1016/0893-6080(89)90020-8.
                    <br/>
                    <br/>
                    Ke, G., Meng, Q., Finley, T., Wang, T., Chen, W., Ma, W., Ye, Q., and Liu, T. 2017. "LightGBM: A Highly Efficient Gradient Boosting Decision Tree." Advances in Neural Information Processing Systems 30. Long Beach, CA: Curran Associates, Inc. 3146-3154. http://papers.nips.cc/paper/6907-lightgbm-a-highly-efficient-gradient-boosting-decision-tree.pdf.Ke, G., Meng, Q., Finley, T., Wang, T., Chen, W., Ma, W., Ye, Q., and Liu, T. 2017. "LightGBM: A Highly Efficient Gradient Boosting Decision Tree." Advances in Neural Information Processing Systems 30. Long Beach, CA: Curran Associates, Inc. 3146-3154. http://papers.nips.cc/paper/6907-lightgbm-a-highly-efficient-gradient-boosting-decision-tree.pdf.
                    <br/>
                    <br/>
                    Kursa, M. B., and Rudnicki W. R. 2010. "Feature Selection with the Boruta Package." Statistical Software Vol. 36, Issue 11: 1-13.
                    <br/>
                    <br/>
                    Pedregosa, F., VAroquaux, G., Gramfort, A., Michel, V., Thirion, B., Grisel, O., Blondel, M., Prettenhofer, P., Weiss, R., DDubourg, V., Vanderplas, J., Passos, A., Cournapeau, D., Brucher, M., Perrot, M., and Duchesnay., E. 2011. "Scikit-learn: Machine Learning in Python." Journal of Machine Learning Research, Vol 12: 2825-2830.
                    <br/>
                    <br/>
                    Prechelt, L. 1998. "Early Stopping - But When?" Neural Networks: Tricks of the Trade 55-69. doi:http://dx.doi.org/10.1007/3-540-49430-8_3.
                    <br/>
                    <br/>
                    Srivastava, N., Hinton, G., Krizhevsky, A., Sutskever, I., Salakhutdinov, R. 2014. "Dropout: A Simple Way to Prevent Neural Networks from Overfitting." Journal of Machine Learning Research 15 1929-1958. http://jmlr.org/papers/v15/srivastava14a.html.
                    <br/>
                    <br/>
                    Valderrama, J. O. 2003. "The state of the cubic equations of state." Industrial &amp; engineering chemistry research 42(8): 1603-1618. doi:https://doi.org/10.1021/ie020447b.
                    </p>
                </div>



        </div>

        )
    }
}

export default GpaProject;