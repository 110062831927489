import React, { useState}from 'react';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Modal from './Modal';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100vw',
    position: 'absolute',
    color: '#646478',
    
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: '#646478',
  },
  title: {
    flexGrow: 1,
    color: '#646478',
    textDecoration: 'none',
  },
  bar: {
    color: '#646478',
    backgroundColor: 'rgba(255, 255, 255, .1)',
    boxShadow: '0px 4px 4px 2px rgba(0, 0, 0, 0.25)',
    backdropFilter: 'blur(25px)',
  },
}));

function Header() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false)


  return (
    <div className={classes.root}>
      <AppBar position="relative" className={classes.bar}>
        <Toolbar>
          {/* 
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          */}
          
          <Typography variant="h6" className={classes.title}>
          <Link to="/" className={classes.title}>
            Preston Blackburn
            </Link>
          </Typography>
          
          <Button color="inherit">
              <Link to="/projects" className={classes.title}>
              Projects
              </Link>
          </Button>
          <Button color="inherit" onClick={() => setIsOpen(true)}>
            Contact
          </Button>
          <Modal open={isOpen} onClose={() => setIsOpen(false)} />
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header