import React, { Component } from 'react';

import BellSiphon from '../images/AquaponicsPost1/bell_siphon.png';
import PlumbingSystem from '../images/AquaponicsPost1/pumbing_system.png';
import Reservoir from '../images/AquaponicsPost1/reservoir_side.png';
import WaterProof from '../images/AquaponicsPost1/waterproofing.png';
import WholeSystemSide from '../images/AquaponicsPost1/waterproofing.png';
import SolarSystem from '../images/AquaponicsPost1/solar_system.png';
import SensorResults from '../images/AquaponicsPost1/sensor_results.png';
import CardPhoto from '../images/AquaponicsPost1/aqua_card_photo.png';


class AquaponicsProject extends Component {
    render() {
        return(
            <div className="project-box">
                <h1 className="project-title-text">
                    Solar Powered Aquaponics System With Machine Learning Monitoring
                </h1>
                <h2 className="project-title-text-2">
                    Part 1: Initial Setup
                </h2>
                <p className="project-main-text" style={{display:'block'}}>
                     Over the last year, I haven’t been eating fish in part due to the in-sustainability of commercial fishing and farming; however, 
                     I still want to eat fish that are raised in a more sustainable way, so I turned to aquaponics. Overfishing is still very 
                     prevalent despite the rise in farmed fish 
                     ( &nbsp;
                    <a href="https://ourworldindata.org/fish-and-overfishing#how-much-of-the-world-s-fish-is-managed-sustainably" target="_blank" style={{textDecoration:"none", }}> Overfishing Data</a>   &nbsp; )
                        Furthermore, commercial fishing gear makes up a substantial percentage of the waste in the ocean, around 10% ( &nbsp;
                            <a href="https://www.fao.org/3/i0620e/i0620e02.pdf" target="_blank" style={{textDecoration:"none", }}>  FAO Fisheries and Aquaculture Technical Paper No. 523</a>   &nbsp; )
                    While farmed fish may seem like a good alternative to wild-caught fish, many times farmed fish are fed wild-caught fish. This context inspired me to create my own aquaponics system. 
                    <br />
                    <i> Also, see initiatives by MarViva for marine governance- https://marviva.net/en/  </i>
                    <br />
                    <br />
                    A goal of this project is to try and raise fish and plants in a more sustainable way through aquaponics. Apart from raising fish, 
                    aquaponics is much more water-efficient than soil farming techniques and can produce greater yields per area. Some studies claim
                     that aquaponics can achieve over 95 % water efficiency compared to soil methods. 
                     ( &nbsp;
                    <a href="https://doi.org/10.1016/j.aquaeng.2012.11.008" target="_blank" style={{textDecoration:"none", }}>Recirculating aquaculture systems </a>   &nbsp; )
                    Another goal of the project is to document my findings and provide resources to anyone else who might be interested in setting up a similar system. 
                    <br />
                    <br />
                    This pilot project will help me test and monitor different approaches and assess the sustainability of the system before I scale it up.
                    Since this system will be completely standalone, I will be using solar to power all electronics. For detailed monitoring and assessment
                    I’m using a raspberry pi to control various sensors, and I’m sending the data to AWS. Once I have the data in AWS, I’ll serve it to an app
                    where I can monitor the system’s performance, and I’ll be able to model the future performance with machine learning.  
                    <br />
                    <br />
                    For the first part of this project, I set up and test the key components of the system. The key components that need to be set
                    up are the solar system, plumbing, and monitoring. For each of these components, I’ll provide instructions or links for their
                    setup and links to where to buy the elements of each component. 
                </p>


                <h2 className="project-title-text-2">
                    Part 1 Scope
                </h2>
                <p className="project-main-text" style={{display:'block'}}>
                    <li> Solar setup - Verify the solar setup can run the pump and raspberry pi </li>
                    <li> Plumbing setup - Verify the plumbing setup can continuously fill and drain </li>
                    <li> Monitoring setup - Verify that raspberry pi can run on the solar system and send data to the cloud db </li>
                </p>
                
                <h2 className="project-title-text-2">
                    Solar Setup
                </h2>
                <p className="project-main-text" style={{display:'block'}}>
                    For the solar setup, we will basically need to create a solar-powered outlet. The most difficult part of setting 
                    up this system is making sure all of the elements of the system are compatible with each other. As long as all 
                    of the components are compatible it is just a matter of wiring everything together. 
                    <br/>
                    <br />
                    A diagram of my setup is shown below. At the bottom of this post, you can find all of the Amazon links to these components.
                </p>

                <div className="blog-pics">
                    <img src={SolarSystem} alt = "Solar system setup" width='70%'/>
                </div>

                <p className="project-main-text" style={{display:'block'}}>
                    *A note on wiring the components - Always make sure the battery is connected to the controller
                     before connecting the solar panel. Always take care when handling electronics. 

                     <br />
                     <br />
                     <b> Main Elements </b> 
                     <br />
                     <br />
                     <li> 
                        Solar panel
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li> Provides system with power  </li>
                        <li> As soon as the sun hits the panel it is live, so take necessary precautions</li>
                        </ol>
                     </li>

                     <li> 
                        Solar controller
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li> Make sure that the battery isn’t overcharged and provides other safety features </li>
                        </ol>
                     </li>

                     <li> 
                        Battery
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li> Be sure to use a deep cycle battery. A normal battery is designed to supply short bursts of power, while a deep cycle battery can produce consistent lower levels of power. </li>
                        <li> Store power for when the sun isn’t out (3 days is a good target to shoot for) </li>
                        </ol>
                     </li>

                     <li> 
                        Fuse
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li> If the current goes over 10 Amps it will break the circuit. This prevents the solar controller from being destroyed </li>
                        <li> Manufacturer recommended </li>
                        </ol>
                     </li>

                     <li> 
                        12 guage wire
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li> It is important to make sure that the wire is thick enough to transfer the needed power </li>
                        </ol>
                     </li>

                     <br />
                     <br />

                     <b> Findings </b>

                     <br />
                     <br />
                      <ol>
                      <li> Worked as expected </li> 
                      <li> Waterproofing may be a challenge </li> 
                      <li> The inverter and battery put off heat </li> 
                      <li> Batteries will be the most expensive part </li> 
                      <li> Next steps I’ll look into solar panel and battery sizing to ensure the system can operate for a couple of days without any sun.  </li> 
                      </ol>

                </p>


                <h2 className="project-title-text-2">
                    Pumbing Setup
                </h2>

                <p className="project-main-text" style={{display:'block'}}>
                    For the plumbing setup, we need to make the reservoir, grow bed, and bell siphon. The bell siphon will 
                    drain the grow bed once the water reaches a specific level. The pump moves the water up from the reservoir
                     to the grow bed. A diagram of the setup is shown below. 
                </p>

                <div className="blog-pics">
                    <img src={PlumbingSystem} alt = "Solar system setup" width='70%'/>
                </div>

                <p className="project-main-text" style={{display:'block'}}>
                     <b> Main Elements </b> 
                     <br />
                     <br />
                     <li> 
                        Bell Siphon
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li> When the water reaches a certain level it drains the bed completely  </li>
                        <li> It can take some trial and error to get the siphon to function properly, but I have some good resources linked below. </li>
                        <li> &nbsp;  <a href="https://www.youtube.com/watch?v=Ia1BQFTaG7c" target="_blank" style={{textDecoration:"none", }}> YouTube Video For Setup </a>  </li>
                        <li> &nbsp;  <a href="https://www.ctahr.hawaii.edu/oc/freepubs/pdf/bio-10.pdf" target="_blank" style={{textDecoration:"none", }}> In depth paper on aquaponics bell siphons </a>  </li>
                        </ol>
                     </li>


                     <li> 
                        Clay ball grow bed
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li>The clay balls provide a media for plants to grow in </li>
                        <li>For aquaponics the media needs to be inert so it does not affect the water quality and fish </li>
                        </ol>
                     </li>

                     <li> 
                        Pump
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li>Water should be circulated 1-3 times per hour (ex: 50 gallon system needs a 50-150gph pump) </li>
                        <li> The pump I selected for this pilot project has too high of a flow rate, so you could get by with a much smaller pump 
                             (I scaled down the pilot project from what I originally planned when I got the pump). </li>
                        </ol>
                     </li>

                     <br />
                     <br />

                     <b> Findings </b>

                     <br />
                     <br />
                      <ol>
                      <li> I had some trouble getting the siphon to work, and I’m still troubleshooting to improve its performance.  </li> 
                      <li> Debris built up quickly in the reservoir, so some sort of covering and potentially a filter will need to be added.</li> 
                      </ol>
                </p>

                <h2 className="project-title-text-2">
                    Monitoring Setup
                </h2>


                <p className="project-main-text" style={{display:'block'}}>
                    For the monitoring setup, I’ll use a raspberry pi (mini computer) with sensors to take measurements, and then
                    I’ll send and store those measurements in a database on AWS. In future phases of the project, this will let
                    me monitor the system in real-time. 
                    <br />
                    <br />
                    For the raspberry pi setup, we’ll need to create a script to collect and send the data, set up the
                    raspberry pi to allow for remote connection, and set up a serverless API and database to receive the data. 
                </p>

                
                <p className="project-main-text" style={{display:'block'}}>
                     <b> Main Elements </b> 
                     <br />
                     <br />
                     <li> 
                        Good overview of a Rasberry Pi 4 setup if needed
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li> &nbsp;  <a href="https://www.youtube.com/watch?v=jRKgEXiMtns&t=621s" target="_blank" style={{textDecoration:"none", }}> Raspberry pi setup video </a>  </li>
                        </ol>
                     </li>


                     <li> 
                        Collecting the data
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li> Use the Adafruit-circuitpython-dht library </li>
                        <li> &nbsp;  <a href="https://www.youtube.com/watch?v=EcyuKni3ZTo" target="_blank" style={{textDecoration:"none", }}> DHT 22 Setup Video </a>  </li>
                        <li> &nbsp;  <a href="https://www.piddlerintheroot.com/dht22/" target="_blank" style={{textDecoration:"none", }}> DHT 22 Setup Blog</a>  </li>
                        </ol>
                     </li>

                     <li> 
                        Set up remote connection (connect via SSH)
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li>  <a href="https://libguides.nyit.edu/c.php?g=469894&p=3365470" target="_blank" style={{textDecoration:"none", }}> Setting up SSH on Raspberry Pi Guide </a>  </li>
                        <li> When I was setting it up I ran into an issue connecting to SSH where it wouldn’t
                             connect unless a monitor was plugged in first. Re-flashing the OS fixed the issue. </li>
                        <li> To make sure pi is on the network you can ping the IP address </li>
                        </ol>
                     </li>


                     <li> 
                        Set up the serverless API
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li> If you prefer python over javascript: <a href="https://libguides.nyit.edu/c.php?g=469894&p=3365470" target="_blank" style={{textDecoration:"none", }}> AWS API Setup With Python</a>  </li>
                        <li> If you prefer Javascript over python: <a href="https://libguides.nyit.edu/c.php?g=469894&p=3365470" target="_blank" style={{textDecoration:"none", }}> AWS API Setup With JavaScript </a>  </li>
                        <li> Set up an API gateway on AWS (MQTT is preferred over an API request, but I’ll dive into that in a later part of the project) </li>
                        </ol>
                     </li>



                     <br />
                     <br />

                     <b> Findings </b>

                     <br />
                     <br />
                      <ol>
                      <li> I ran into some issues with the raspberry pi inconsistently sending data. The image below shows 
                          the data I had in the database from the temperature and humidity sensor. From the unevenly 
                          spaced measurements, You can see that there are some errors with the data being sent. This could 
                          be due to poor wi-fi connectivity, but I will need to investigate further.   </li>  
                      </ol>

                </p>

                <div className="blog-pics">
                    <img src={SensorResults} alt = "Solar system setup" width='70%'/>
                </div>




                <h2 className="project-title-text-2">
                    System Pictures
                </h2>

                <div className="blog-pics">
                    <img src={CardPhoto} alt = "Solar system setup" width='70%'/>
                </div>

                <div className="blog-pics">
                    <img src={Reservoir} alt = "Solar system setup" width='70%'/>
                </div>

                <div className="blog-pics">
                    <img src={BellSiphon} alt = "Solar system setup" width='70%'/>
                </div>

                <div className="blog-pics">
                    <img src={WaterProof} alt = "Solar system setup" width='70%'/>
                </div>




                <h2 className="project-title-text-2">
                    Product List
                </h2>


                <p className="project-main-text" style={{display:'block'}}>
                     <b> Solar </b> 
                     <br />
                     <br />

                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li> &nbsp;  <a href="https://www.amazon.com/gp/product/B01LY02BOA/ref=ppx_yo_dt_b_asin_title_o01_s00?ie=UTF8&psc=1" target="_blank" style={{textDecoration:"none", }}> Panel </a>  </li>
                        <li> &nbsp;  <a href="https://www.amazon.com/gp/product/B08HLH58L1/ref=ppx_yo_dt_b_asin_title_o06_s00?ie=UTF8&psc=1" target="_blank" style={{textDecoration:"none", }}> Panel Brackets </a>  </li>
                        <li> &nbsp;  <a href="https://www.amazon.com/gp/product/B00DGXGKWA/ref=ppx_yo_dt_b_asin_title_o00_s00?ie=UTF8&psc=1" target="_blank" style={{textDecoration:"none", }}> Solar to wire connectors </a>  </li>
                        <li> &nbsp;  <a href="https://www.amazon.com/gp/product/B07NPDWZJ7/ref=ppx_yo_dt_b_asin_title_o01_s01?ie=UTF8&psc=1" target="_blank" style={{textDecoration:"none", }}> Controler </a>  </li>
                        <li> &nbsp;  <a href="https://www.amazon.com/gp/product/B07RSB6PMN/ref=ppx_yo_dt_b_asin_title_o01_s01?ie=UTF8&psc=1" target="_blank" style={{textDecoration:"none", }}> Inverter </a>  </li>
                        <li> &nbsp;  <a href="https://www.amazon.com/gp/product/B00K8E0WAG/ref=ppx_yo_dt_b_asin_title_o04_s00?ie=UTF8&psc=1" target="_blank" style={{textDecoration:"none", }}> Battery </a>  </li>
                        <li> &nbsp;  <a href="https://www.amazon.com/gp/product/B07RY8Y2QV/ref=ppx_yo_dt_b_asin_title_o01_s00?ie=UTF8&psc=1" target="_blank" style={{textDecoration:"none", }}> Fuse </a>  </li>
                        <li> &nbsp;  <a href="https://www.amazon.com/gp/product/B081VDY95P/ref=ppx_yo_dt_b_asin_title_o02_s00?ie=UTF8&psc=1" target="_blank" style={{textDecoration:"none", }}> 12 Guage quick disconnect </a>  </li>
                        </ol>


                     <b> Plumbing </b> 
                     <br />
                     <br />

                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li> &nbsp;  <a href="https://www.amazon.com/gp/product/B07L54HB83/ref=ppx_yo_dt_b_asin_title_o03_s00?ie=UTF8&psc=1" target="_blank" style={{textDecoration:"none", }}> Pump</a>  </li>
                        <li> &nbsp;  <a href="https://www.homedepot.com/p/HDX-55-Gal-Tough-Storage-Tote-in-Black-with-Yellow-Lid-HDX55GONLINE-4/205597365" target="_blank" style={{textDecoration:"none", }}> 55 gal reservoir tote </a>  </li>
                        <li> &nbsp;  <a href="https://www.cabelas.com/shop/en/plano-medium-storage-trunk?rrec=true" target="_blank" style={{textDecoration:"none", }}>68 Qt media bed tote </a>  </li>
                        <li> &nbsp;  <a href="https://www.amazon.com/gp/product/B07XRGNXWL/ref=ppx_yo_dt_b_asin_title_o01_s00?ie=UTF8&psc=1" target="_blank" style={{textDecoration:"none", }}> Bulkhead fitting </a>  </li>
                        <li> &nbsp;  <a href="https://www.amazon.com/gp/product/B092SGM2KZ/ref=ppx_yo_dt_b_asin_title_o01_s00?ie=UTF8&psc=1" target="_blank" style={{textDecoration:"none", }}> ¾ in PVC adaptor (for bulkhead fitting) </a>  </li>
                        <li> &nbsp;  <a href="https://www.amazon.com/gp/product/B009LNOZQ2/ref=ppx_yo_dt_b_asin_title_o06_s02?ie=UTF8&psc=1" target="_blank" style={{textDecoration:"none", }}> Clay Pebbles </a>  </li>
                        <li> &nbsp;  <a href="https://www.homedepot.com/s/pvc?NCNI-5" target="_blank" > ¾ in pvc, 4 in pvc, 2 in pvc </a>  </li>
                        <li> &nbsp;  <a href="https://www.homedepot.com/s/pex?NCNI-5" target="_blank" > ½ in pex </a> (I’d recommend using PVC or other tubing instead if you haven’t used pex before. I used pex because I already had some on hand) </li>
                        <li> &nbsp;  <a href="https://www.homedepot.com/s/pex%2520ball%2520valve?NCNI-5" target="_blank"> Ball valve </a> (To control the flow to the media bed)</li>
                        </ol>

                    <b> Monitoring </b> 
                     <br />
                     <br />


                     <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li> &nbsp;  <a href="https://www.raspberrypi.com/products/raspberry-pi-4-desktop-kit/" target="_blank" style={{textDecoration:"none", }}> Raspberry Pi Kit</a>  </li>
                        <li> (I bought all the individual components, but I recommend getting a kit if you haven’t set one up before) </li>
                        <li> &nbsp;  <a href="https://www.amazon.com/Gowoops-Temperature-Humidity-Measurement-Raspberry/dp/B073F472JL/ref=sr_1_1_sspa?crid=381FYYJR74EQM&keywords=dht22+sensor&qid=1653276482&sprefix=dht22+senso%2Caps%2C104&sr=8-1-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUFZMjZFRzhDUlJTMkUmZW5jcnlwdGVkSWQ9QTA3ODQ4NjMxSVBOMk5WUTRRNjhDJmVuY3J5cHRlZEFkSWQ9QTAzMzgzMzAyRVpOOEFQMERUU1pIJndpZGdldE5hbWU9c3BfYXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ==" target="_blank" style={{textDecoration:"none", }}> DHT 22 Sensor </a>  </li>
                    </ol>

                </p>


                <h2 className="project-title-text-2">
                    Fixes For Part 2
                </h2>

                <p className="project-main-text" style={{display:'block'}}>

                     <li> 
                        Solar
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li> Add additional batteries </li> 
                        <li> Improve waterproofing </li> 
                        </ol>
                     </li>

                     <li> 
                        Plumbing
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li>Improve bell siphon </li> 
                        </ol>
                     </li>

                     <li> 
                        Monitoring 
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                        <li>Adding additional Sensors </li> 
                        <li>Investigate inconsistencies in API requests </li>
                        <li>Heat sink for raspberry pi - was very hot after running 12 hrs outside </li>
                        <li>Independent power supply </li>
                        </ol>
                     </li>

                </p>


                <h2 className="project-title-text-2">
                    Part 2 Scope
                </h2>
                
                <p className="project-main-text" style={{display:'block'}}>

                    <li> Part 1 Fixes</li>
                    <li>Verify that water temperature is suitable for fish </li>
                    <li>Setup monitoring website </li>
                    <li>Add plants </li>


                </p>




            </div>          
        )
    }
}

export default AquaponicsProject;