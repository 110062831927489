import React, { Component } from 'react';
import ReactDom from 'react-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Linkedinlogo from '../codeicons/LI-In.png'

const useStyles = makeStyles(() => ({


heroButton2Style: {
    background: 'rgba(243, 243, 243, 0.0)',
    color: '#f44336',
    fontSize: '24px',

    
  },
}));

function Modal({ open, onClose }) {
    const classes = useStyles();
    if(!open) return null

    return ReactDom.createPortal(
    <>
    <div className="modalOverlay" />
    <div className="modalStyle">

        <div className="contacth1">  Contact </div>
        <hr></hr>
        <br />

        
        <div className="contacth2">  Phone  </div>
        <div className="contacth3"> 970-297-8092</div>
        <br>
        </br>

        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
        <div className="contacth2">  Email  </div>
        <div style={{float:'right'}}>
        <a href="http://www.linkedin.com/in/prestonblackburncheme" rel="noopener noreferrer" target="_blank">
            <img className="linkedImg" src={Linkedinlogo} aria-hidden="true" />
        </a>
        </div>
        </div>

        <div className="contacth3"> prestonblckbrn@gmail.com </div> 
        <br></br>
        <div></div>

        <div style={{float:'right'}}>
        <Button onClick={onClose} size="small" className={classes.heroButton2Style}> 
            Close
        </Button>  
        </div>


    </div>
 


    </>,
    document.getElementById('portal')
    )
}

export default Modal;