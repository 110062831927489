import React, { Component } from 'react';


import BertResults from '../images/DockerGPUPost/bert_training_times.png';
import ModelComp from '../images/DockerGPUPost/model_size_comparision.png';
import NvidiaArc from '../images/DockerGPUPost/docker_nvida_image.png';
import GpuLoad from '../images/DockerGPUPost/gpu_load_2.png';
import CpuLoad from '../images/DockerGPUPost/cpu_load_training.png';



class DockerGPU extends Component {
    render() {
        return(
            <div className="project-box">
                <h1 className="project-title-text">
                    Machine Learning For Your Gaming PC
                </h1>
                <h2 className="project-title-text-2">
                        Set Up Docker, Windows 11, and Nvidia GPUs for machine learning acceleration. 
                </h2>
                <p className="project-main-text" style={{display:'block'}}>

                Setting up local graphics cards (GPUs) to accelerate machine learning tasks can be tedious with
                 dependencies between GPU drivers, CUDA drivers, python, and python libraries to manage. With the
                  release of Windows 11, we can automatically handle dependencies between CUDA, Python, and deep
                   learning libraries such as Tensorflow, by using Docker. By using docker you can worry less about
                    driver compatibility issues between your drivers for gaming and drivers for deep learning workloads. 
                <br />
                <br />
                You can receive major benefits from GPU acceleration depending on what types of models that you run.
                 Transformers are a popular choice for many NLP tasks, however, even just fine tuning smaller transformers 
                 such as DistilBERT can take hours if you are only using your computer's CPU. The results of training a 
                 Small BERT model on my CPU, GPU, and Google Colab are shown below. By switching to my GPU I was able to
                  fine-tune the model in minutes instead of hours. All the notebooks I used for testing are in this
                   GitHub repo:&nbsp;
                 <a href="https://github.com/PrestonBlackburn/gpu-acceleration-comparison" target="_blank" style={{textDecoration:"underline", }}> Github Repo - ML Training Notebooks</a> 
                <br />
                </p>


                <div className="blog-pics">
                    <img src={BertResults} alt = "Bert Training Times" width='80%'/>
                </div>

                <p className="project-main-text" style={{display:'block'}}>
                Furthermore, the current trend in machine learning is to train larger and larger models to achieve
                 higher accuracy, which in turn take longer to fine-tune. As models get larger, fine-tuning using
                  GPU accelerated training will become more and more necessary. The figure below compares some 
                  high-performing models over the last decade. I recommend taking a look at more models on Papers
                   with Code and comparing the size of the recent top-scoring models with older models for yourself.
                      &nbsp;
                    <a href="https://paperswithcode.com/sota/image-classification-on-imagenet" target="_blank" style={{textDecoration:"underline", }}> PapersWithCode Imagenet Comparison </a> 
                </p>

                <div className="blog-pics">
                    <img src={ModelComp} alt = "Model size compairison" width='80%'/>
                </div>



                <h2 className="project-title-text-2">
                    Overview of setup
                </h2>

                <p className="project-main-text" style={{display:'block'}}>
                    There are a lot of good resources for different elements of this setup, so I'll 
                    focus on bringing all of the components together in a step by step guide. For 
                    this walkthrough I'll go through upgrading to Windows 11 from Windows 10 (not
                     required, but recommended) and setting up docker to run TensorFlow on a Jupyter notebook. 
                </p>


                <div className="blog-pics">
                    <img src={NvidiaArc} alt = "Model size compairison" width='70%'/>
                </div>

                
                <h2 className="project-title-text-2">
                    Part 1 Upgrading to Windows 11 
                    <br />
                    (skip if you already have W11)
                </h2>

                <p className="project-main-text" style={{display:'block'}}>
                    Check Windows 11 compatability:
                    <br />
                    <br />
                    There are a few things to check to make sure that you can upgrade
                     to Windows 11. There are workarounds for some of the requirmeents,
                      but the requirements are good security features to have enabled.
                      <br />
                      You can check your PC using PC Health Check:  &nbsp;
                      <a href="https://www.microsoft.com/en-us/windows/windows-11#pchealthcheck" target="_blank" style={{textDecoration:"underline", }}> PC Health Check </a> 
                      <br />
                      Two new requirements for Windows 11 are secure boot and TPM 2.0 which may
                       not be enabled on older computers. 
                    If your PC fails any of the PC health checks follow the steps below.
                     Otherwise skip to the next section. 
                </p>

                <p className="project-main-text" style={{display:'block'}}>
                    1. Setting up Secure Boot
                    <br />
                     <br />
                    You'll need to enable secure boot in your computers bios. Follow allong with this
                    video to set it up if it isn't already &nbsp;
                    <a href="https://www.youtube.com/watch?v=vurIhOhTF0A" target="_blank" style={{textDecoration:"underline", }}> Secure Boot Setup - YouTube </a> 
                    <br />
                     <br />
                     2. Enable TPM 2.0
                     <br />
                     <br />
                     Check processor compatibility.
                    If the processor is compatible then you should be good to go, but there may be some bios options that need to be changed
                    TMP 2.0 will be called PTT for intel chips. Checkout this video for how to setup TMP 2.0. &nbsp;
                    <a href="https://www.youtube.com/watch?v=h-_c5vLj03A" target="_blank" style={{textDecoration:"underline", }}> TMP 2.0 Setup - YouTube </a> 
                    <br />
                    <br />
                    3. Download windows 11                    
                    <br />
                    <br />
                    Windows 11 still not officially rolled out to some PCs like mine, 
                    so you might need to download it form their site here &nbsp;
                    <a href="https://www.microsoft.com/en-us/software-download/windows11" target="_blank" style={{textDecoration:"underline", }}> Windows 11 Install </a> 
                </p>


                <h2 className="project-title-text-2">
                     Part 2 Setup docker for GPU
                </h2>

                <p className="project-main-text" style={{display:'block'}}>
                    Requirements
                    <br />
                     <br />
                     1. WSL-2
                     <br />
                     2. Docker Desktop
                     <br />
                     3. Nvidia GPU Driver
                </p>


                <p className="project-main-text" style={{display:'block'}}>
                    Make sure WSL-2, Docker, and Nvidia drivers are up to date
                    <br />
                     <br />
                     1. Install or Update WSL-2 
                     <br />
                     <br />
                     run <i>wsl -l -v</i>  in terminal to check your WSL version. It should show version 2.
                     Also update WSL to the latest version with this command in the terminal <i> wsl --update </i>.  (my version was 5.10.102.1)

                     <br />
                     If it isn't installed follow this documentation &nbsp;
                    <a href="https://docs.microsoft.com/en-us/windows/wsl/install" target="_blank" style={{textDecoration:"underline", }}> WSL Install Guide </a> 
                    <br />
                     <br />
                     2. Install and Update Docker Desktop
                     <br />
                     <br />
                     Follow the doces to install docker or update through the Docker desktop app. (my version was 4.6.1) &nbsp;
                    <a href="https://docs.docker.com/desktop/windows/install/" target="_blank" style={{textDecoration:"underline", }}> Docker Install Guide </a> 
                    <br />
                    <br />
                    3. Update NVIDIA Driver                  
                    <br />
                    <br />
                    If you are using a PC for gaming just make sure your graphics card is updated in the GeForce Experience desktop app. (my version was 512.15 ) &nbsp;
                    <a href="https://www.nvidia.com/en-us/geforce/geforce-experience/" target="_blank" style={{textDecoration:"underline", }}> Nvidia GeForce App </a> 
                </p>

                <h2 className="project-title-text-2">
                     Part 3 Test and Setup Jupyter Notebook
                </h2>

                <p className="project-main-text" style={{display:'block'}}>
                    Once all of your apps are up to date test out if docker can detect your GPU
                    <br />
                    In the terminal run <i> docker run --gpus all nvcr.io/nvidia/k8s/cuda-sample:nbody nbody -gpu -benchmark </i>
                     <br />
                     If Docker can detect your GPU you can try to spin up a docker container with jupyter notebook and
                      TensorFlow to test if TensorFlow can detect your GPUs:
                     <br />
                     <br />
                     <i> docker run -it --gpus all -p 8888:8888 tensorflow/tensorflow:latest-gpu-jupyter </i>
                     <br />
                     <br />
                     Check out the full Nvidia guide here: &nbsp;
                    <a href="https://docs.nvidia.com/cuda/wsl-user-guide/index.html" target="_blank" style={{textDecoration:"underline", }}> Nvidia WSL Guide </a> 
                    <br />
                    And the list of TensorFlow tested containers here:  &nbsp;
                    <a href="https://hub.docker.com/r/tensorflow/tensorflow/" target="_blank" style={{textDecoration:"underline", }}> Official TensorFlow Docker Images </a> 
                     <br />
                     <br />
                     Next you'll probably want to mount a volume so you can access your existing Jupyter notebooks
                      and save your Jupyter notebooks outside of docker
                     <br />
                     <br />
                      In the terminal run:
                      <br />
                      <br />
                       <i> docker run -it --gpus all --rm -v C:\Users\your_username\your_jupyter_notebook_folder/:/tf/local_notebooks -p 8888:8888  tensorflow/tensorflow:latest-gpu-jupyter </i>
                     <br />
                     <br />
                     To break down the command:
                     <br />
                     <br />
                     <b> docker run </b> &nbsp; &nbsp; runs docker containers
                     <br />
                     <br />
                     <b> -it </b> &nbsp; &nbsp; basically makes the container look like a terminal connection session
                     <br />
                     <br />
                     <b> --gpus all </b>  &nbsp; &nbsp;assigns all available gpus to the docker container
                     <br />
                     <br />
                     <b> --rm </b> &nbsp; &nbsp; automatically clean up the container and remove the file system when the container exits
                     <br />
                     <br />
                     <b> -v </b> &nbsp; &nbsp; for specifying a volume
                     <br />
                     <br />
                     <b> C:\Users\your_username\your_jupyter_notebook_folder </b>   &nbsp; &nbsp; Local filepath that to use. This will be wherever you normally save your jupyter notebooks. 
                     <br />
                     <br />
                     <b> /tf/local_notebooks </b>  &nbsp; &nbsp; The filepath in docker
                     <br />
                     <br />
                     <b> -p </b>  &nbsp; &nbsp; expose ports, jupyter notebooks run on port 8888 by default so we
                      map port 8888 from the container to our local host to keep things simple
                      <br />
                     <br />
                     <b> tensorflow/tensorflow:latest-gpu-jupyter </b>  &nbsp; &nbsp; The image that we want to build. This comes 
                     pre-setup from tensorflow, but we can modify it needed. 

                     <br />
             </p>


             <h2 className="project-title-text-2">
                 Testing
            </h2>


            <p className="project-main-text" style={{display:'block'}}>
                    For my testing I copied a TensorFlow tutorial for fine tuning BERT for sentiment
                    analysis on the imbd movie dataset. I also compared the performance to a Google 
                    Colab notebook with a free cloud GPU. My results are show below, and the notebooks
                    can be found on GitHub. &nbsp;
                    <a href="https://github.com/PrestonBlackburn/gpu-acceleration-comparison" target="_blank" style={{textDecoration:"underline", }}> GitHub Notebooks </a> 
                
                <br />
                <br />
                <b>Testing Results: </b>
                <br />
                <br />
                Local GPU Time:
                <br />
                ~2:40 minutes per epoch
                <br />
                Total Time: 16.1 minutes
                <br />
                <br />
                Local CPU Time:
                <br />
                ~22:00 minutes per epoch
                <br />
                Total Time: 131.8 minutes
                <br />
                <br />
                Google Colab Time:
                <br />
                ~4:30 minutes per epoch
                <br />
                Total Time: 26.6 minutes

                <br />
                <br />
                <br />

                My System
                <br />
                CPU: Intel i7-8700K
                <br />
                GPU: Nvidia RTX 2070
                <br />
                RAM: 24 GB

                <br />
                <br />
                <br />
                All tests were based on this notebook from TensorFlow:  &nbsp;
                 <a href="https://www.tensorflow.org/text/tutorials/fine_tune_bert" target="_blank" style={{textDecoration:"underline", }}> TensorFlow BERT Fine Tuning </a> 
             
            </p>

            <p className="project-main-text" style={{display:'block'}}>
                GPU Training
            </p>

            <div className="blog-pics">
                <img src={GpuLoad} alt = "GPU Testing Load" width='70%'/>
            </div>

            <p className="project-main-text" style={{display:'block'}}>
                CPU Training
            </p>

            <div className="blog-pics">
                <img src={CpuLoad} alt = "CPU Testing Load" width='70%'/>
            </div>



            </div>          
        )
    }
}

export default DockerGPU;