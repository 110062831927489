import React, { Component } from 'react';
import WidgetCards from '../components/Cards/WidgetCards';
import WidgetCardsProduction from '../components/Cards/WidgetCardsProduction';
import LineChart from '../components/Charts/LineChart';
import  DoughnutChart from '../components/Charts/DonutChart';
import styles from '../components/Styles/dash.module.css';
import { emissionData, energyData, bblsData, fetchEnergyConsChange } from '../dashAPI';




class Dashboard extends Component {

    constructor(props){
        super(props);
    }
        state = {
        lastEmissionData: {},
        Year: {},
    }

    async componentDidMount() {
        const fetchedData = await emissionData();
        const lastEmissionData = fetchedData[fetchedData.length-1]

        this.setState({ lastEmissionData: lastEmissionData});
    }


    render() {

        const { lastEmissionData } = this.state;

        return(
            <div className={styles.container} style={{fontStyle: 'Roboto'}}>
                <div>
                    <h1 font-style="Roboto" font-size='40px' padding-top='250px'>
                        <br></br>
                        <br></br>
                        Energy Summary Dashboard
                    </h1>
                </div>
                <WidgetCardsProduction data={lastEmissionData} />
                <LineChart />
                <div className={styles.containerBottom}>
                        <WidgetCards />
                        <DoughnutChart data={lastEmissionData}/>
                </div>
            </div>  
        )
    }
}

export default Dashboard;



