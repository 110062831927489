import axios from 'axios';



const emissionsUrl = 'https://raw.githubusercontent.com/PrestonBlackburn/BP-datasets/main/BPEmissions.json'

export const emissionData = async () => {
    try {

        {/* 
        const { data: { Year, US, Europe, China, World} } = await axios.get(emissionsUrl);  
        return { Year, US, Europe, China, World};
        */}
        const { data } = await axios.get(oilProduction);
        {/*  Not sure why this isn't work, so returned whole json*/}
        const emissionsData = data.map ((data) => ({
            Year: data.Year, 
            US: data.Total_US,
            Europe: data.Total_Europe,
            China: data.Total_China,
            World: data.Total_World,
        }))

        return emissionsData;
    } catch (error) {
    }
}

const bblsUrl = 'https://raw.githubusercontent.com/PrestonBlackburn/BP-datasets/main/BPOilConsumption.json'

export const bblsData = async () => {
    try {
        const response = await axios.get(bblsUrl);

        return response;

    } catch (error) {
    }
}

const energyUrl = 'https://raw.githubusercontent.com/PrestonBlackburn/BP-datasets/main/BPEnergySourcesExajoules.json'

export const energyData = async () => {
    try {
        const response = await axios.get(energyUrl);

        return response;

    } catch (error) {
    }
}

const exxonPriceUrl = 'https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=XOM&interval=60min&outputsize=compact&apikey=RFNKWZH6OAT1AFK8'

export const exxonData = async () => {
    try {

        {/* 
        const { data: { Year, US, Europe, China, World} } = await axios.get(emissionsUrl);  
        return { Year, US, Europe, China, World};
        */}
        const { data } = await axios.get(exxonPriceUrl);
        {/*  Not sure why this isn't work, so returned whole json*/}
        const exxonData = {
        }
        return data;
    } catch (error) {
    }
}


const oilProduction = 'https://raw.githubusercontent.com/PrestonBlackburn/BP-datasets/main/BPOilConsumption.json'

export const fetchOilProduction = async () => {
    try {
        const { data } = await axios.get(oilProduction)
        const modifiedData = data.map((oilProduction) => ({
            totalWorld: oilProduction.Total_World,
            Year: oilProduction.Year,
            totalUS: oilProduction.Total_US,
            totalEurope: oilProduction.Total_Europe,
            totalChina: oilProduction.Total_China,
        }))
      return modifiedData;
    } catch (error) {
        console.log('error pulling oil production data');

    }
}

const energyConsLink = 'https://raw.githubusercontent.com/PrestonBlackburn/BP-datasets/main/BPEnergySourcesExajoules.json'

export const fetchEnergyCons = async () => {
    try {

        const  { data } = await axios.get(energyConsLink);
        const modifiedData = data.map((energyCons) => ({
            Year: energyCons.Year,
        }))
        return modifiedData


    } catch (error) {
        console.log('error pulling energy consumption data')
    }
}

