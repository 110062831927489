import React, { Component } from 'react';
import fbGraph from '../images/prodPost/fb_data.png';
import arpsGraph from '../images/prodPost/ARPS_data.png';
import mcGraph from '../images/prodPost/MC_dropout_data.png';
import summaryTable from '../images/prodPost/Results Summary.png';
import wellGraph from '../images/prodPost/well_data.png';
import modelComparison from '../images/prodPost/Model_comparisons.png';
import arpsEq from '../images/prodPost/arps_equation.jpg';


class ProdProject extends Component {
    render() {
        return(
            <div className="project-box">
                <h1 className="project-title-text">
                    Oil Production Forecasting With Neural Networks
                </h1>
                <p className="project-main-text">
                Production forecasting is used to predict the future performance of an oil or gas well. Typically, these predictions
                 are referred to as decline curves because the production of a well decreases with time. Usually, oil and gas wells 
                 decline exponentially. Traditionally Arps curves have been used to predict production by taking advantage of this 
                 exponential decline rate. However, predictions can be improved using modern machine learning techniques, such as 
                 Bayesian deep learning. Bayesian models consider information outside of the dataset, unlike traditional (frequentist) 
                 models. Bayesian methods have the advantage of providing uncertainty intervals for the model’s predictions, which
                  frequentist methods typically struggle to produce. Having uncertainty intervals can be useful when evaluating a 
                  forecasting model, especially when poor forecasts can be costly.  
                  <br />
                  <br />
                    Below I predict the production rates and associated error of a case study to get experience using Bayesian deep 
                    learning techniques.  To get a sense of how well the model performs, I compare the results to two other Bayesian 
                    models:  a Bayesian Arps curve and Facebook’s forecasting model, “Prophet.”
                </p>

                <p className="project-main-text"  style={{display:'block'}}>
                If you would like to know more go to this repo: &nbsp;
                <a href="https://github.com/CamDavidsonPilon/Probabilistic-Programming-and-Bayesian-Methods-for-Hackers" target="_blank"> Programming and Bayesian Methods</a>  
                </p>


                <h2 className="project-title-text-2">
                    Production Data
                </h2>
                <p className="project-main-text">
                    For this case study, data was taken from the Texas Railroad Commission (RRC). This well was chosen because of its
                     long history and typical decline profile. The data was split into two datasets: training (the first 280 months) 
                     and test (the remaining 40 months). The models will make predictions on the test dataset, shown by the blue line 
                     below. 
                </p>
                <p className="project-main-text" style={{display:'block'}}>
                    The Dataset can be found at on my github: &nbsp;
                    <a href= "https://raw.githubusercontent.com/PrestonBlackburn/production-data-texas/master/Texas_well_production.csv" target="_blank"> Production Data</a>
                </p>

                <div className="blog-pics">
                    <img src={wellGraph} alt = "well-data" width='95%'/>
                </div>


                <h2 className="project-title-text-2">
                    Bayesian Neural Network using Monte Carlo Dropout
                </h2>

                <p className="project-main-text">
                    My Bayesian deep learning model’s architecture takes aspects of top-performing neural network architectures and
                    combines them with Monte Carlo (MC) Dropout to approximate a Bayesian prediction. The architecture used is a
                    dilated convolutional neural network (CNN) with LSTM cells, which have been shown to perform well in data science
                    competitions like those hosted by Kaggle. Applying MC dropout can estimate what a Bayesian network might look 
                    like while keeping computation costs down. The mean prediction, 95% credible interval, and 50 predictions ran 
                    with MC dropout are shown in the graph below. For the 50 predictions, the opacity is turned down to make the mean
                    prediction and credible interval easier to see. The model seems like it might be a little under confident about
                    its forecast, but I will make another post going over the reliability diagram in the future.
                </p>

                <div className="blog-pics">
                    <img src={mcGraph} alt="MC-Dropout" width='95%'/>
                </div>


                <h2 className="project-title-text-2">
                    ARPs using Bayesian Regression 
                </h2>

                <p className="project-main-text" style={{display:'block'}}>
                    Probabilistic methods can be applied to ARPs curve predictions to simulate various potential predictions.
                    For this model I used the <a href="https://petrowiki.spe.org/Production_forecasting_decline_curve_analysis" target="_blank">Arps equation </a> 
                    mentioned at the beginning of this paper combined with the <a href="https://docs.pymc.io" target="_blank">python API PyMC3</a> .
                    For the sake of simplicity, I will not cover specifics such as Markov Chain Monte Carlo (MCMC) methods or 
                    Bayesian terminology such as priors and posteriors. 50 of the posterior predictive lines are plotted in the
                    example below.
                </p>

                <div className="blog-pics">
                <br></br>
                    Arps Equation Used
                    <br></br>
                    <br></br>
                    <img src={arpsEq} alt = "arps-equation" width='60%'/>
                </div>

                
                <div className="blog-pics">
                    <img src={arpsGraph} alt = "arps-graph" width='95%'/>
                </div>

                <h2 className="project-title-text-2">
                    Facebook Prophet
                </h2>

                <p className="project-main-text">
                    Facebook Prophet uses Bayesian methods for prediction that are easily applied at scale. It is based on a 
                    generative additive model that considers changepoints, trends, seasonality, and more. In the model below,
                    the default FB prophet model was used with minimal tuning. Better results may be obtained by tuning. The
                    uncertainty interval is shown by the light blue lines in the graph below. The model also seems to be overconfident
                    about its uncertainty interval since some of the actual production values are out of range.  
                </p>

                <div className="blog-pics">
                    <img src={fbGraph} alt = "fb-prophet-graph" width='95%'/>
                </div>
                

                <h2 className="project-title-text-2">
                    Model Comparisons
                </h2>

                <p className="project-main-text">
                    I compare the mean prediction of all three models below for the last 40 months below. As expected, the 
                    Bayesian neural network outperforms both the Arps model and Facebook Prophet for this case study. The Bayesian
                    neural network did the best at capturing the drop in production after 295 months. Additional metrics in the 
                    performance summary table show that the Bayesian neural network outperforms the other two models for this case
                    study. 
                </p>

                <div className="blog-pics">
                            <img src={modelComparison} alt="model-comparisons" width='95%'/>
                </div>

                <div className="blog-pics" padding="2rem">
                    <br></br>
                        Performance Summary
                    <br></br>
                    <br></br>
                            <img src={summaryTable} alt="model-summary"/>
                </div>

                <h2 className="project-title-text-2">
                    To Be Continued in Part 2...
                </h2>

                <p className="project-secondary-text">
                    The results could potentially be improved even further if additional data were fed into the network, such as the
                    well’s workover history. In a future post, I may investigate adding additional data from the chemical disclosure 
                    registry Frac Focus. Furthermore, the model’s performance should be evaluated to see if it is under-confident or
                    overconfident using a reliability diagram.  
                </p>
            

                <p className="project-main-text" padding='2rem' style={{display:'block'}}>
                    Please feel free to contact me with any questions or comments on LinkedIn: &nbsp;
                    <a href="https://www.linkedin.com/in/prestonblackburncheme" target="_blank">  Preston's LinkedIn Page </a>  
                </p>


            </div>          
        )
    }
}

export default ProdProject;